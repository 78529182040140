import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave
} from '@ionic/react';
import { useParams } from 'react-router';

import { Descriptions, Row, Col, notification, List, Avatar, Button, Steps } from 'antd';
import { Card } from 'antd';
import { Switch, Radio } from 'antd';
import { Input } from 'antd';
import { ReloadOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { message, Tag } from 'antd';
import { DatePicker, Space, } from 'antd';
import './Page2.css';
import { PageHeader } from 'antd';
import React, { useState } from 'react';
import logo from '../pages/images/image6.jpg';

import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas';
import { DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { UIStore } from '../store/UIStore';
const dateFormat = 'YYYY/MM/DD';

const ProjectManager: React.FC = (props: any) => {
  const [applicationpacks, setapplicationpacks] = useState<any[]>([])
  const { Step } = Steps;
  const [commentbox1, setShowcommentbox1] = useState(true);
  const [commentbox2, setShowcommentbox2] = useState(true);
  const [commentbox3, setShowcommentbox3] = useState(true);
  const [commentbox4, setShowcommentbox4] = useState(true);
  const [commentbox5, setShowcommentbox5] = useState(true);
  const [commentbox6, setShowcommentbox6] = useState(true);
  const [commentbox7, setShowcommentbox7] = useState(true);
  const [commentbox8, setShowcommentbox8] = useState(true);
  const [commentbox9, setShowcommentbox9] = useState(true);
  const [commentbox10, setShowcommentbox10] = useState(true);
  const [commentbox11, setShowcommentbox11] = useState(true);
  const [data, setdata] = useState<any[]>([])
  const CurrentProject = UIStore.useState(s => s.CurrentProject);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string; }>();
  var sigCanvas: any = ({});
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }



  function CheckObject() {
    const isEmpty = !Object.values(ApplicationObject).some(x => (x !== null && x !== ''));
    console.log(ApplicationObject);
    if (isEmpty === false) {
      notification['error']({
        message: 'Error',
        description:
          'All fields must be completed',
      });
    }
  }

  function loadProject(projectobject: any) {



    UIStore.update(s => {
      s.CurrentProject = projectobject;
      console.log(CurrentProject);
      props.history.push("/EligibilityCriteria");
    })

    // console.log(projectobject);


    //  
  }




  const user = JSON.parse(localStorage.getItem("userobject") || '{}');
  const [ApplicationObject, setState] = useState({
    userid: "",
    SignatureApplication: "",
    Location: "",
    Date: "",
    Signatory: "",
    Capacity: "",
    CIBD: "",
    Ownership: "",
    ApplicationParticipation: "",
    MinimumWage: "",
    RingFenced: "",
    DevelopmentPlan: "",
    Training: "",
    Experience: "",
    DevelopmentAgreement: "",
    Assesment: "",
    MaterialsAgreement: "",
  });



  useIonViewDidEnter(() => {
    axios.get('https://dev.northroom.co.za/api/spfund/legacy/users/projectmanager')
      .then(function (response) {

        setdata(response.data);
        console.log(response.data);


      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
  });

  async function SubmitBaseline() {

    message
      .loading('Action in progress..', 5)
      .then(() => message.success('Submission Succeeded', 5));
    try {
      const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/submitjvscreen', ApplicationObject).then((response) => {

        // message.success(response.data);
        // console.log(response);
        props.history.push("/TDRiskOfficer");


      });

    } catch (error) {
      message.error(error);
    }

  }

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));

    setState(prevState => ({
      ...prevState,
      userid: user.id
    }));
  };
  const handleChangecheckbox = (e: any, name: any) => {

    setState(prevState => ({
      ...prevState,
      [name]: e
    }));

  };

  function onChangeDatePicker(DateWorkCompleted: any, dateString: any) {
    setState(prevState => ({
      ...prevState,
      Date: dateString
    }));
  }

  const handleChangeSignature = (e: any, name: any) => {

    setState(prevState => ({
      ...prevState,
      [name]: e
    }));
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"

              title="Project Manager"

            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen >
        <IonRow style={{ paddingLeft: "5%" }}>
          <IonCol class="ion-padding" size="11">
            <Steps current={2} status="error">
              <Step title="Eligibility Criteria" description="Complete" />
              <Step title="Application Form" description="Complete" />
              <Step title="Compliance Checklist" description="Awaiting Applications Officer verification" />
              <Step title="CIC investment" description="Incomplete" />
            </Steps>
          </IonCol>
        </IonRow>

        <>

          <List
            dataSource={[
              {
                name: 'Application 1',

              },

            ]}
            bordered
            renderItem={item => (


              data.map((dataobject) =>
                <>

                  <List.Item

                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar src={logo} />
                      }
                      title={<a href="https://northroom.co.za">Project Application ID:{dataobject.ProjectName}</a>}
                      description="In Progress Application"
                    />

                  </List.Item>

                  <List.Item>

                    <IonRow>
                      <IonCol>
                        <Link to="/BaselineReport">
                          <Button
                            onClick={() => { loadProject(dataobject); }}
                            type="primary"
                            >
                            Conduct Baseline
                      </Button>
                        </Link>
                      </IonCol>


                      <IonCol>
                        <Button type="primary" icon={<DownloadOutlined />}>
                          Download
        </Button>
                      </IonCol>

                    </IonRow>

                  </List.Item>

                </>
              )
            )}
          />

        </>

      </IonContent>
    </IonPage>


  );
};

export default ProjectManager;
