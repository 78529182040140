import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonItemDivider,
    IonLabel,
    useIonViewDidEnter,
    useIonViewDidLeave
  } from '@ionic/react';
  import { useParams } from 'react-router';
  
  import { Descriptions, Row, Col, notification, List, Avatar, Button, Steps } from 'antd';
  import { Card } from 'antd';
  import { Switch, Radio } from 'antd';
  import { Image } from 'antd';
  import { Input } from 'antd';
  import { PlusOutlined, ProjectOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
  import moment from 'moment';
  import { message, Tag } from 'antd';
  import { DatePicker, Space, } from 'antd';
  import './Page2.css';
  import { PageHeader } from 'antd';
  import React, { useState } from 'react';
  import logo from '../pages/images/image6.jpg';
  import banner from '../pages/images/banner.png';
  
  import axios from 'axios';
  import SignatureCanvas from 'react-signature-canvas';
  import { DownloadOutlined } from '@ant-design/icons';
  import Modal from 'antd/lib/modal/Modal';
import { Link } from 'react-router-dom';
  const dateFormat = 'YYYY/MM/DD';
  
  const HelpPage: React.FC = (props: any) => {
  
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonRow>
              <PageHeader
                className="site-page-header"
  
               
  
              />
            </IonRow>
          </IonToolbar>
        </IonHeader>
  
        <IonContent fullscreen >
            
  
    <div>
    <img src={banner} alt="logo" style={{ width: "100%" }}  />
    </div>
    
         <IonRow>
        <Card title="(1) My Projects"  style={{ width: "50%" }}>
      <p>Once on the “My projects” page you must create a new project.</p>
      <p>You will then proceed to the “Eligibility Criteria”.</p>
      
    </Card>

    <Card title="(2) Eligibility Criteria" style={{ width: "50%" }}>
      <p>Once on the “Eligibility Criteria” page you will then answer whether you have registered with the CIDB, if you have then you must upload your CIDB registration file.</p>
      <p>Once the file CIDB file has been uploaded then you will continue to answer the succeeding questions on the “Eligibility Criteria” page.</p>
      <p>If any of the questions are answered 'No' then a reason must be provided or an approriate query as to why. </p>
      
    </Card>
    </IonRow>
    <IonRow>
    <Card title="(3) Application Form"  style={{ width: "50%" }}>
      <p>You will proceed to fill out the application form to be assessed by the SP team.</p>
      <p>When your application form has been filled out and completed you will be able to proceed to the compliance checklist.</p>
    </Card>

    <Card title="(4) Compliance Checklist"  style={{ width: "50%" }}>
      <p>In the compliance checklist you will have to upload every single document before they can proceed to the declaration page.</p>
      
    </Card>
    </IonRow>
    <Card title="(5) Declaration Form"  style={{ width: "50%" }}>
      <p>Once on the declaration form you will be required to fill out the approriate fields</p>
    
    </Card>
    <br></br>
    <div className="ion-text-center">
    <Link to="/Projects">
    <Button type="primary">Get Started</Button>
    </Link>
    </div>

        </IonContent>
      </IonPage>
  
  
    );
  };
  
  export default HelpPage;
  