import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Tooltip,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import {
  PlusOutlined,
  ProjectOutlined,
  ReloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import { DatePicker, Space } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { setcurrentproject } from "../store/actions";
import { UIStore } from "../store/UIStore";

const dateFormat = "YYYY/MM/DD";

const Projects: React.FC = (props: any) => {
  const { Step } = Steps;

  const [ProjectName, setProjectName] = useState("");

  const [data, setdata] = useState<any[]>([]);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};

  const createText = (
    <span>Create a project, if you do not have one created below.</span>
  );
  const loadText = <span>Load an existing project.</span>;
  const buttonWidth = 70;

  const [projects, setprojects] = useState<any[]>([]);
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  const InitialProject = {
    id: "",
    ProjectName: "",
    CurrentStatus: "",
    CIDB: false,
    Ownership: false,
    ApplicationParticipation: false,
    MinimumWage: false,
    RingFenced: false,
    DevelopmentPlan: false,
    Training: false,
    Experience: false,
    DevelopmentAgreement: false,
    Assesment: false,
    MaterialsAgreement: false,
    CIDBCommentBox: "",
    OwnershipCommentBox: "",
    ApplicationParticipationBox: "",
    MinimumWageBox: "",
    RingFencedBox: "",
    DevelopmentPlanBox: "",
    TrainingBox: "",
    ExperienceBox: "",
    DevelopmentAgreementBox: "",
    AssesmentBox: "",
    MaterialsAgreementBox: "",
    ProjectNumber: "",
    ApplicationProjectName: "",
    BusinessName: "",
    BusinessOwners: "",
    Date: "",
    VersionNumber: "",
    DocumentName: "",
    Author: "",
    Comments: "",
    BusinessOwnerFirstName: "",
    BusinessOwnerSurname: "",
    BeneficiaryID: "",
    MaleFemale: "",
    Under36: "",
    Race: "",
    CellTell: "",
    PhysicalAddress: "",
    CompanyName: "",
    BbbeeRating: "",
    BlackOwnership: "",
    WomanOwnership: "",
    YouthOwnership: "",
    CompanyContact: "",
    BusinessSector: "",
    BusinessRegistrationDate:
      "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    TradingStartDate:
      "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    Classification: "",
    Services: "",
    GPSCoordinates: "",
    AnnualRevenue: "",
    FundingMethod: "",
    PermanentEmployees: "",
    SeasonalEmployees: "",
    TemporaryJobs: "",
    Date2: "",
    BusinessOwnerName1: "",
    BusinessOwnerName2: "",
    BeneficiaryID2: "",
    FemaleMale: "",
    Under36Years: "",
    Race1: "",
    BeneficiaryContact: "",
    TellCell: "",
    PhysicalAddress1: "",
    CompanyName1: "",
    BBBEERating1: "",
    BlackOwnership1: "",
    WomanOwnership1: "",
    YouthOwnership1: "",
    CompanyContact1: "",
    BusinessSector1: "",
    BusinessSize: "",
    BusinessRegistration: "",
    TradingStartDate1: "",
    Classification1: "",
    ProductCommodity: "",
    GPSCoordinates1: "",
    AnnualRevenue1: "",
    FundingMethod1: "",
    PermanentEmployees1: "",
    SeasonalEmployees1: "",
    TempJobs: "",
    SmmeDate: "",
    LegalEntity: "",
    TaxClearance: "",
    PostalAddress: "",
    CIDBGrade: "",
    Title: "",
    Initials: "",
    IDPassport: "",
    CountryOfIssue: "",
    Designation: "",
    Telephone: "",
    Cellphone: "",
    Email: "",
    Projection: "",
    ValueOfProject: "",
    Client: "",
    ClientContact: "",
    ProjectDuration: "",
    ProjectStart:
      "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    ProjectEnd: "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    Location: "",
    PermanentJobs: "",
    BusinessProjects: "",
    SignatureDeclaration: "",
    DecDate: "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    RepNameTitle: "",
    Consent: false,
    SignatureCredit: "",
    CAClient: "",
    RegistrationNumber: "",
    RepresentativeName: "",
    IdentityNumber: "",
    ClientName: "",
    OfficeBlock: "",
    Address: "",
    City: "",
    CADate: "",
    PostalCode: "",
    SigningLocation: "",
    Witnesses: "",
    Witnesses2: "",
    Witnesses3: "",
    Witnesses4: "",
    SignatureJV: "",
    PartnerName: "",
    JVRegistrationNumber: "",
    HeadOfficeLocation: "",
    JointVentureName: "",
    JVClientName: "",
    RepresentativeNameA: "",
    RepresentativeNameB: "",
    LocationOfSigning: "",
    JVDate: "",
    JointVenturePartnerName: "",
    NameTitle: "",
    Spif: "",
    BOQ: "",
    ImplementationPlan: "",
    Letterofappointment: "",
    BankingStatements: "",
    ManagementAccounts: "",
    Insurance: "",
    CSD: "",
    CIDBDoc: "",
    NHBRC: "",
    TaxClearanceDoc: "",
    Vat: "",
    DepartLabor: "",
    personnel: "",
    AllDirectors: "",
    MartialSTatus: "",
    BBEECert: "",
    Companyregdocuments: "",
    companyprofile: "",
    ProofAddress: "",
    ApplicationForm: "",
    BaselineForm: "",
    CIPCForm: "",
    CreditagreementForm: "",
    DeclarationForm: "",
    JVForm: "",
    EligibilityCriteriaForm: "",
    CompanyDescriptionExtendedServices: "",
    CompanyDescriptionExtendedArea: "",
    BusinessStructureFamilyBusiness: "",
    BusinessStructureOwners: "",
    BusinessStructureRolesResponsibilities: "",
    MarketTarget: "",
    MarketOffTakersCurrent: "",
    MarketOffTakersPipeline: "",
    MarketStrategyCurrent: "",
    GovernanceSystems: "",
    GovernanceFinancialManagement: "",
    GovernanceAccounting: "",
    GovernanceHR: "",
    SkillsGap: "",
    ReasonForIntervention: "",
    BusinessChallenges: "",
    OwnerShortMediumLongGoals: "",
    OwnerGoalsSuccess: "",
    OwnerGoalsSuccessionPlan: "",
    ProjectManagerName: "",
    ProjectDescription: "",
    CAAddendum: "",
    JVAddendum: "",
  };

  useIonViewDidEnter(() => {
    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/legacy/users/projectlist/" + user.id
      )
      .then(function (response) {
        //23 items
        console.log(response.data);
        console.log(user.id)
        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState("Project Name");
  // const [modalText2, setModalText2] = React.useState('Some other input');

  const showModal = () => {
    setVisible(true);
  };

  /*
  useEffect(() => {
    console.log(CurrentProject);
  }, [CurrentProject]);
*/

  function loadProject(projectobject: any) {
    UIStore.update((s) => {
      s.CurrentProject = projectobject;
    });
    props.history.push("/EligibilityCriteria");
  }

  const CreateProject = async () => {
    setModalText("Processing Request");
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);

    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));

    try {
      const request = await axios
        .post("https://dev.northroom.co.za/api/spfund/legacy/users/createproject", {
          userid: user.id,
          ProjectName: ProjectName,
        })
        .then((response) => {
          message.success(response.data);
          console.log(response);
          // props.history.push("/NewProject");

          UIStore.update((s) => {
            s.CurrentProject = InitialProject;
          });

          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/legacy/users/projectlist/" +
                user.id
            )
            .then(function (response) {
              //23 items

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error) {
      message.error(error);
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const checkForException = (object: any) => {
   

    try {
      if (object.AppOfficerExceptioncomment.length>0) {
        return object.AppOfficerExceptioncomment;
      } else if (object.CompOfficerExceptioncomment.length > 0) {
        return object.CompOfficerExceptioncomment;
      } else if (object.RiskOfficerExceptioncomment.length > 0) {
        return object.RiskOfficerExceptioncomment;
      }
    } catch (e) {
      return "No Exception";
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader className="site-page-header" title="My Projects" />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow>
          <IonCol>
            <Card>
              <Tooltip
                style={{ width: buttonWidth, marginLeft: buttonWidth * 4 + 24 }}
                placement="right"
                title={createText}
              >
                <Button
                  type="primary"
                  onClick={showModal}
                  icon={<PlusOutlined />}
                >
                  Create New Project
                </Button>
              </Tooltip>
              <Modal
                title="New Project"
                visible={visible}
                onOk={CreateProject}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
              >
                <p>{modalText}</p>
                <Input
                  value={ProjectName}
                  size="large"
                  placeholder=""
                  name={"ProjectName"}
                  onChange={(e) => {
                    setProjectName(e.target.value);
                  }}
                  prefix={<ProjectOutlined />}
                />

                <br></br>
                <br></br>
                {/* <p>{modalText2}</p>

                <Input 
                size="large" 
                placeholder="" 
                prefix={<UserOutlined />} /> */}
              </Modal>
            </Card>
          </IonCol>
        </IonRow>

        <>
          <List
            dataSource={[{}]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <a>Project Application Name:{dataobject.ProjectName}</a>
                      }
                      description="Application in Progress"
                    />
                    <IonCol>
                      <Tag color="geekblue">
                        Project Stage: {dataobject.CurrentStatus}
                      </Tag>
                    </IonCol>

                    <IonCol>
                      <Tooltip
                        style={{
                          width: buttonWidth,
                          marginLeft: buttonWidth * 4 + 24,
                        }}
                        placement="right"
                        title={loadText}
                      >
                        <Button
                          onClick={() => {
                            loadProject(dataobject);
                          }}
                          type="primary"
                          icon={<ReloadOutlined />}
                        >
                          Load
                        </Button>
                      </Tooltip>
                    </IonCol>
                    <IonCol>
                      <Tag color="red">{checkForException(dataobject)}</Tag>
                    </IonCol>

                    {/* <p>Registration with CIBD</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} onChange={e => { handleChangecheckbox(e, "CIBD") }} />
                <br></br>
                <br></br>
                {commentbox1 ? <TextArea placeholder="Provide a reason/Ask for help?" rows={4} /> : null} */}

                    {/* <Tag>
    <a href="https://dev.northroom.co.za/api/spfund/static/b9c1227be29a3ff58094364939823ae456dcd1978008df99.png" download>CPIC Page 1 Report</a>
    </Tag>
    <Tag>
    <a href="https://dev.northroom.co.za/api/spfund/static/7ed4fb7ab52101ac1bf8df1f7470c97ff14886f784423227.png" download>CPIC Page 2 Report</a>
    </Tag> 
    <Tag>
    <a href="https://dev.northroom.co.za/api/spfund/static/8b654c2c8191611bb54f2e543dfd6952b3c2f0df913b6f13.png" download>CPIC Page 3 Report</a>
    </Tag>  */}
                    {/* <Tag>
    <a href={dataobject.Credit} download>Credit Agreement</a>
    </Tag>  */}
                  </List.Item>

                  <List.Item>
                    <IonRow></IonRow>
                    <IonRow></IonRow>
                  </List.Item>
                </>
              ))
            }
          />
        </>
      </IonContent>
    </IonPage>
  );
};

export default Projects;
