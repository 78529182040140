import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  withIonLifeCycle,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave
} from '@ionic/react';
import { useParams } from 'react-router';

import { Descriptions, Row, Col } from 'antd';
import { Card, Input } from 'antd';
import { Switch } from 'antd';
import { Collapse } from 'antd';
import { DatePicker, Space, Button, Steps, Tooltip } from 'antd';
import { getuserObject } from "../store/actions";
import axios from "axios";
import { Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './Page2.css';
import { PageHeader } from 'antd';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import { UIStore } from "../store/UIStore";
import Modal from 'antd/lib/modal/Modal';
const ComplienceChecklist: React.FC = (props:any) => {
  const CurrentProject = UIStore.useState(s => s.CurrentProject);

async function SubmitCompliance() {
  console.log(CurrentProject);
  message
    .loading('Action in progress..', 5)
    .then(() => message.success('Submission Succeeded', 5));
  try {
    const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate/Compliance', CurrentProject).then((response) => {

      // message.success(response.data);
      // console.log(response);
      // props.history.push("/ComplianceChecklist");


    });

  } catch (error) {
    console.error(error);
  }

}

  const { Step } = Steps;

  const { name } = useParams<{ name: string; }>();

  function onChange(checked: any) {
    console.log(`switch to ${checked}`);
  }

  function handleChange(value: any) {
    console.log(`selected ${value}`);
  }

  const { Panel } = Collapse;

  function callback(key: any) {
    console.log(key);
  }

  const [isModalHelpVisible, setIsModalHelpVisible] = useState(false);

  const showHelpModal = () => {
    setIsModalHelpVisible(true);
  };

  const handleHelpOk = () => {
    SubmitCompliance();
    props.history.push("/DeclarationPage")
    setIsModalHelpVisible(false);
  };

  const handleHelpCancel = () => {
    setIsModalHelpVisible(false);
  };

  const genExtra = (item: any) => {


    if (item === true) {

      return <> Document Submitted  <CheckCircleTwoTone twoToneColor="#52c41a" /> </>

    }
    else {
      return <> Document Outstanding  <ExclamationCircleTwoTone twoToneColor="#FF0000" /> </>



    }


  };

  const text = <span>Submit and proceed to declaration form</span>;




  const user = JSON.parse(localStorage.getItem("userobject") || '{}');

  useIonViewDidEnter(() => {

  });

  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
  });

  // async function SubmitApplicationForm() {



  //   message
  //     .loading('Action in progress..', 5)
  //     .then(() => message.success('Submission Succeeded', 5));


  // }



  function beforeUpload(file: any) {
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
        message.error('You can only upload Pdf files!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
        message.error('Document must smaller than 5MB!');
    }  
    
    return (file.type === 'application/pdf' && file.size / 1024 / 1024 < 5) ? true : Upload.LIST_IGNORE;

}



  // const propsbaselinereport = {
  //   name: 'propsbaselinereport',
  //   action: 'https://dev.northroom.co.za/api/spfund/uploadimage',
  //   headers: {
  //     authorization: user.id,
  //   },
  //   onChange(info: any) {
  //     if (info.file.status !== 'uploading') {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === 'done') {
  //         message.success(`${info.file.name} file uploaded successfully`);
  //       UIStore.update(s => {s.CurrentProject.CIDBDoc = info.file.response.path})
      
  //     } else if (info.file.status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };
  const propscompanyprofile = {
    name: 'propscompanyprofile',
    
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.companyprofile = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsCompanyregdocuments = {
    name: 'propsCompanyregdocuments',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.Companyregdocuments = info.file.response.path})
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }

      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsBBEECert = {
    name: 'propsBBEECert',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.BBEECert = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsMartialSTatus = {
    name: 'propsMartialSTatus',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.MartialSTatus = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsAllDirectors = {
    name: 'propsAllDirectors',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.AllDirectors = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsProofAddress = {
    name: 'propsProofAddress',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.ProofAddress = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propspersonnel = {
    name: 'propspersonnel',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.personnel = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const propsDepartLabor = {
    name: 'propsDepartLabor',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
   async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.DepartLabor = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsVat = {
    name: 'propsVat',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.Vat = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsTaxClearance = {
    name: 'propsTaxClearance',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.TaxClearanceDoc = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsNHBRC = {
    name: 'propsNHBRC',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.NHBRC = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  // const propsCIDB = {
  //   name: 'propsCIDB',
  //   action: 'https://dev.northroom.co.za/api/spfund/uploadimage',
  //   headers: {
  //     authorization: user.id,
  //   },
  //   onChange(info: any) {
  //     if (info.file.status !== 'uploading') {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === 'done') {
  //         message.success(`${info.file.name} file uploaded successfully`);
  //       UIStore.update(s => {s.CurrentProject.CIDBDoc = info.file.response.path})
  //     } else if (info.file.status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };
  const propsCSD = {
    name: 'propsCSD',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.CSD = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsInsurance = {
    name: 'propsInsurance',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.Insurance = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsManagementAccounts = {
    name: 'propsManagementAccounts',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.ManagementAccounts = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsBankingStatements = {
    name: 'propsBankingStatements',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.BankingStatements = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsLetterofappointment = {
    name: 'propsLetterofappointment',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.Letterofappointment = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsImplementationPlan = {
    name: 'propsImplementationPlan',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.ImplementationPlan = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsBOQ = {
    name: 'propsBOQ',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.BOQ = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsSpif = {
    name: 'propsSpif',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
   async onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        UIStore.update(s => {s.CurrentProject.Spif = info.file.response.path});
        try {
          const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate', CurrentProject).then((response) => {
    
            // message.success(response.data);
             console.log(response);

    
    
          });
    
        } catch (error) {
          console.error(error);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"

            title="Compliance Checklist"

          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">
          <IonRow style={{ paddingLeft: "2%", width: "100vw" }}>
            <IonCol class="ion-padding" size="8">
              <Steps current={2} status="error">
                <Step title="Eligibility Criteria" description="Completed" />
                <Step title="Application Form" description="Completed" />
                <Step title="Compliance Checklist" description="Complete Compliance Checklist and Declaration form" />
                <Step title="CIC investment" description="Incomplete" />
              </Steps>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-padding">
              <Collapse defaultActiveKey={['1']} onChange={callback} style={{ width: "70vw", paddingLeft: "2%", paddingRight: "2%", paddingBottom: "2%" }}>

                <Panel header="Company profile" key="1" extra={genExtra(CurrentProject.companyprofile.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propscompanyprofile}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Company registration documents" key="2" extra={genExtra(CurrentProject.Companyregdocuments.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload  beforeUpload={beforeUpload} maxCount={1} {...propsCompanyregdocuments}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="B-BBEE certificate" key="3" extra={genExtra(CurrentProject.BBEECert.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload  beforeUpload={beforeUpload} maxCount={1} {...propsBBEECert}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Marital status and contract" key="4" extra={genExtra(CurrentProject.MartialSTatus.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsMartialSTatus}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="ID of all owners or directors at group level" key="5" extra={genExtra(CurrentProject.AllDirectors.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsAllDirectors}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Proof of address" key="6" extra={genExtra(CurrentProject.ProofAddress.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsProofAddress}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Company and project  responsible personnel" key="7" extra={genExtra(CurrentProject.personnel.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propspersonnel}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Department of labour" key="8" extra={genExtra(CurrentProject.DepartLabor.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload  beforeUpload={beforeUpload} maxCount={1} {...propsDepartLabor}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="VAT certificate" key="9" extra={genExtra(CurrentProject.Vat.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsVat}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="TAX clearance certificate and TAX pin" key="10" extra={genExtra(CurrentProject.TaxClearanceDoc.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsTaxClearance}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>


                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="NHBRC certificate" key="11" extra={genExtra(CurrentProject.NHBRC.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsNHBRC}>
                        <div style={{ paddingLeft: "6vw" }}>
                          <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="CSD registration" key="12" extra={genExtra(CurrentProject.CSD.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsCSD}>
                        <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Proof of insurance including liability cover" key="13" extra={genExtra(CurrentProject.Insurance.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsInsurance}>
                        <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Financials/Management accounts" key="14" extra={genExtra(CurrentProject.ManagementAccounts.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload  beforeUpload={beforeUpload} maxCount={1} {...propsManagementAccounts}>
                        <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Banking statements (not older than 6 months)" key="15" extra={genExtra(CurrentProject.BankingStatements.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsBankingStatements}>
                        <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Letter of appointment" key="16" extra={genExtra(CurrentProject.Letterofappointment.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel  style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsLetterofappointment}>
                        <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Implementation plan" key="17" extra={genExtra(CurrentProject.ImplementationPlan.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsImplementationPlan}>
                        <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Priced bills of quantities" key="18" extra={genExtra(CurrentProject.BOQ.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsBOQ}>
                        <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>

                <Panel header="Signed SPIF agreement" key="19" extra={genExtra(CurrentProject.Spif.length>5)}>

                  <IonRow className="ion-align-items-center ion-justify-content-center">

                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel style={{ paddingLeft: "7vw" }}><strong>Upload Documents</strong></IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel><strong></strong></IonLabel>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>

                    </IonCol>

                    <IonCol>
                      <Upload beforeUpload={beforeUpload} maxCount={1} {...propsSpif}>
                        <div style={{ paddingLeft: "6vw" }}>
                        <Button icon={<UploadOutlined />}>Click to Upload Files</Button>
                        </div>
                      </Upload>
                    </IonCol>

                    <IonCol>

                    </IonCol>
                  </IonRow>


                </Panel>


              </Collapse>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class=" ion-text-center ">
             
                <Tooltip placement="top" title={text}>
                <Button  style={{ marginRight: "30vw" }} size={"large"} type="primary" onClick={() => { showHelpModal(); }}> Submit</Button>
                <Modal title="" visible={isModalHelpVisible} onOk={handleHelpOk} onCancel={handleHelpCancel}>
                      <p><strong>Proceeding to the Declaration page</strong></p>
                      <p>You will be required to provide a signature in order to complete your application</p>
                     
                    </Modal>
                </Tooltip>
             
            </IonCol>
          </IonRow>
        </Space>
      </IonContent>
    </IonPage>


  );
};

export default ComplienceChecklist;