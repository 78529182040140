import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";
import {
  Descriptions,
  Row,
  Col,
  notification,
  Steps,
  Tag,
  Table,
  Menu,
  Dropdown,
  Select,
  Modal,
  Upload,
} from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import {
  LockOutlined,
  PlusOutlined,
  ProjectOutlined,
  SaveOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { message } from "antd";
import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import ImgCrop from "antd-img-crop";
import logo from "../pages/images/image6.jpg";
import { RegisterAPI, RegisterAPIRoles } from "../store/actions";

const dateFormat = "YYYY/MM/DD";

const AssignRoles: React.FC = (props: any) => {
  const [User, setState] = useState({
    Email: "",
    Password: "",
    Password2: "",
    FullName: "",
    ID: "",
    ProfilePicture: "",
    cellphone: "",
  });
  const initialUser = {
    Email: "",
    Password: "",
    Password2: "",
    FullName: "",
    ID: "",
    ProfilePicture: "",
    cellphone: "",
  };

  const { Option } = Select;

  function randomString(length: number, chars: any) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  const children: {} | any | undefined = [];

  children.push(
    <Option
      value={"APPLICATIONOFFICER"}
      key={randomString(
        32,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )}
    >
      Applications officer
    </Option>
  );
  children.push(
    <Option
      value={"COMPLIANCEOFFICER"}
      key={randomString(
        32,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )}
    >
      Compliance Officer
    </Option>
  );
  children.push(
    <Option
      value={"RISKOFFICER"}
      key={randomString(
        32,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )}
    >
      Risk Officer
    </Option>
  );
  children.push(
    <Option
      value={"TECMEMBER"}
      key={randomString(
        32,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )}
    >
      TEC Member
    </Option>
  );
  children.push(
    <Option
      value={"PROJECTMANAGER"}
      key={randomString(
        32,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )}
    >
      Project Manager
    </Option>
  );
  children.push(
    <Option
      value={"FUNDMANAGER"}
      key={randomString(
        32,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )}
    >
      Fund Manager
    </Option>
  );
  children.push(
    <Option
      value={"FINANCEADMINISTRATOR"}
      key={randomString(
        32,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )}
    >
      Finance Administrator
    </Option>
  );

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);


  const showModal = () => {
    setVisible(true);
  };

  async function CreateUser() {
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));

    console.log(User);
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/updateuserrole",
          User
        )
        .then((response) => {
          console.log(response);
          props.history.push("/AssignRoles");
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }

    setState(initialUser);
  }

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setState(initialUser);
    setVisible(false);
  };

  const handleRegister = () => {
    if (User.Password !== User.Password2) {
      message.error("Passwords do not match!");
      return;
    } else {
      message.loading({ content: "Submitting Register..." });
      const API = "https://dev.northroom.co.za";
      try {
        axios
          .post(API + "/api/spfund/legacy/users/registeruser", {
            email: User.Email,
            password: User.Password,
            ProfilePicture: User.ProfilePicture,
            ID: User.ID,
            FullName: User.FullName,
            cellphone: User.cellphone,
          })
          .then(function (response) {
            console.log(response.data);
            message.success("Registration Successful");
            axios
              .get("https://dev.northroom.co.za/api/spfund/legacy/users")
              .then(function (response) {
                SetData(response.data);
                console.log(response.data);
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              });
          });
      } catch (error) {
        message.error(error);
      }

      setState(initialUser);
      setVisible(false);
    }
  };

  const [data, SetData] = useState<any[]>([]);

  async function SaveChanges() {
    message
      .loading("Action in progress..", 4)
      .then(() => message.success("User role successfully changed", 5));

    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/updateuserrole",
          data
        )
        .then((response) => {
          console.log(response);
        });
    } catch (error) {
      message.error(error);
    }
  }

  useIonViewDidEnter(() => {
    axios
      .get("https://dev.northroom.co.za/api/spfund/legacy/users")
      .then(function (response) {
        SetData(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  const { name } = useParams<{ name: string }>();

  //const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleChangeselect(value: any, record: any) {
    let temparr = [...data];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    let index = temparr.findIndex((x) => x.id === record.id);
    temparr[index].UserType = value;
    SetData(temparr);
  }

  const columns = [
    {
      title: "Full Name",
      dataIndex: "FullName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "UserType",
      render: (tags: any) => <Tag color="blue">{tags}</Tag>,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Select
          allowClear
          style={{ width: "300px" }}
          placeholder={""}
          defaultValue={[]}
          onChange={(value) => {
            handleChangeselect(value, record);
          }}
        >
          {children}
        </Select>
      ),
    },
  ];
  //registration Modal
  const [fileList, setFileList] = useState([]);
  const style = { paddingLeft: "130px" };
  const style2 = { paddingLeft: "40px" };
  const label = { paddingLeft: "10px" };
  const label2 = { paddingLeft: "25px" };
  const button = { paddingRight: "50px" };
  const img = { padding: "40px" };

  function onChange(info: any) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      setState((prevState) => ({
        ...prevState,
        ProfilePicture: info.file.response.path,
      }));
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return file.type === "image/jpeg" && file.size / 1024 / 1024 < 2
      ? true
      : Upload.LIST_IGNORE;
  }

  const copyuseridentification = {
    name: "copyuseridentification",
    action: "https://dev.northroom.co.za/api/spfund/legacy/uploadimage/main",

    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setState((prevState) => ({
          ...prevState,
          ID: info.file.response.path,
        }));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  //-------

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              // onBack={() => null}
              title="User management"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Card>
          <IonRow>
            <IonCol size="11">
              <Space style={{ marginBottom: 16 }}></Space>
              <Card>
                <Button
                  type="primary"
                  onClick={showModal}
                  icon={<PlusOutlined />}
                >
                  Create New User
                </Button>

                <Modal
                  visible={visible}
                  onCancel={handleCancel}
                  width={1000}
                  centered
                  footer={[<Button></Button>]}
                >
                  <div>
                    <IonRow style={{ marginLeft: "25%" }}>
                      <Card style={{ width: 500 }}>
                        <IonRow>
                          <IonCol>
                            <img src={logo} alt="logo" />
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <Input
                              size="large"
                              value={User.Email}
                              type="email"
                              width="100px"
                              onChange={handleChange}
                              name="Email"
                              placeholder="Email"
                              prefix={<UserOutlined />}
                            />
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <Input
                              size="large"
                              value={User.FullName}
                              type="text"
                              width="100px"
                              onChange={handleChange}
                              name="FullName"
                              placeholder="Full Name"
                              prefix={<UserOutlined />}
                            />
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <Input
                              size="large"
                              value={User.cellphone}
                              type="number"
                              width="100px"
                              onChange={handleChange}
                              name="cellphone"
                              placeholder="Cellphone Number"
                              prefix={<UserOutlined />}
                            />
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <Input
                              size="large"
                              value={User.Password}
                              type="password"
                              onChange={handleChange}
                              name="Password"
                              placeholder="Password"
                              prefix={<LockOutlined />}
                            />
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <Input
                              size="large"
                              value={user.Password2}
                              type="password"
                              onChange={handleChange}
                              name="Password2"
                              placeholder="Confirm Password"
                              prefix={<LockOutlined />}
                            />
                          </IonCol>
                        </IonRow>

                        <IonRow style={style}>
                          <IonCol>
                            <IonRow>
                              <label style={label}>
                                Upload a copy of your ID
                              </label>
                            </IonRow>

                            <Upload
                              beforeUpload={beforeUpload}
                              maxCount={1}
                              {...copyuseridentification}
                            >
                              <Button icon={<UploadOutlined />}>
                                Click to Upload Files
                              </Button>
                            </Upload>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol style={style}>
                            <label style={label2}>Upload profile picture</label>
                            <IonCol style={style2}>
                              <ImgCrop>
                                <Upload
                                  action="https://dev.northroom.co.za/api/spfund/legacy/uploadimage/main"
                                  listType="picture-card"
                                  onChange={onChange}
                                  beforeUpload={beforeUpload}
                                  style={img}
                                  name={"profilepicture"}
                                >
                                  {fileList.length < 5 && "+ Upload"}
                                </Upload>
                              </ImgCrop>
                            </IonCol>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <Button
                              onClick={() => {
                                handleRegister();
                              }}
                              block
                              type="primary"
                            >
                              Register
                            </Button>
                          </IonCol>
                        </IonRow>
                      </Card>
                    </IonRow>
                  </div>
                </Modal>
              </Card>
              <Table columns={columns} dataSource={data} />
              <IonRow style={{ paddingLeft: "45%" }}>
                <IonCol>
                  <Button
                    onClick={() => {
                      SaveChanges();
                    }}
                    type="primary"
                    icon={<SaveOutlined />}
                  >
                    Save Changes
                  </Button>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </Card>
      </IonContent>
    </IonPage>
  );
};

export default AssignRoles;
