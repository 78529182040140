import { Store } from "pullstate";

export const UIStore = new Store({
  CurrentProject: {
    id: "",
    ProjectName: "",
    CurrentStatus: "",
    CIDB: false,
    Ownership: false,
    ApplicationParticipation: false,
    MinimumWage: false,
    RingFenced: false,
    DevelopmentPlan: false,
    Training: false,
    Experience: false,
    DevelopmentAgreement: false,
    Assesment: false,
    MaterialsAgreement: false,
    CIDBCommentBox: "",
    OwnershipCommentBox: "",
    ApplicationParticipationBox: "",
    MinimumWageBox: "",
    RingFencedBox: "",
    DevelopmentPlanBox: "",
    TrainingBox: "",
    ExperienceBox: "",
    DevelopmentAgreementBox: "",
    AssesmentBox: "",
    MaterialsAgreementBox: "",
    ProjectNumber: "",
    ApplicationProjectName: "",
    BusinessName: "",
    BusinessOwners: "",
    Date: "",
    VersionNumber: "",
    DocumentName: "",
    Author: "",
    Comments: "",
    BusinessOwnerFirstName: "",
    BusinessOwnerSurname: "",
    BeneficiaryID: "",
    MaleFemale: "",
    Under36: "",
    Race: "",
    CellTell: "",
    PhysicalAddress: "",
    CompanyName: "",
    BbbeeRating: "",
    BlackOwnership: "",
    WomanOwnership: "",
    YouthOwnership: "",
    CompanyContact: "",
    BusinessSector: "",
    BusinessRegistrationDate:
      "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    TradingStartDate:
      "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    Classification: "",
    Services: "",
    GPSCoordinates: "",
    AnnualRevenue: "",
    FundingMethod: "",
    PermanentEmployees: "",
    SeasonalEmployees: "",
    TemporaryJobs: "",
    Date2: "",
    BusinessOwnerName1: "",
    BusinessOwnerName2: "",
    BeneficiaryID2: "",
    FemaleMale: "",
    Under36Years: "",
    Race1: "",
    BeneficiaryContact: "",
    TellCell: "",
    PhysicalAddress1: "",
    CompanyName1: "",
    BBBEERating1: "",
    BlackOwnership1: "",
    WomanOwnership1: "",
    YouthOwnership1: "",
    CompanyContact1: "",
    BusinessSector1: "",
    BusinessSize: "",
    BusinessRegistration: "",
    TradingStartDate1: "",
    Classification1: "",
    ProductCommodity: "",
    GPSCoordinates1: "",
    AnnualRevenue1: "",
    FundingMethod1: "",
    PermanentEmployees1: "",
    SeasonalEmployees1: "",
    TempJobs: "",
    SmmeDate: "",
    LegalEntity: "",
    TaxClearance: "",
    PostalAddress: "",
    CIDBGrade: "",
    Title: "",
    Initials: "",
    IDPassport: "",
    CountryOfIssue: "",
    Designation: "",
    Telephone: "",
    Cellphone: "",
    Email: "",
    Projection: "",
    ValueOfProject: "",
    Client: "",
    ClientContact: "",
    ProjectDuration: "",
    ProjectStart:"Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    ProjectEnd: "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    Location: "",
    PermanentJobs: "",
    BusinessProjects: "",
    SignatureDeclaration: "",
    DecDate: "Fri Jan 1 2021 00:07:45 GMT+0200 (South Africa Standard Time)",
    RepNameTitle: "",
    Consent: false,
    SignatureCredit: "",
    CAClient: "",
    RegistrationNumber: "",
    RepresentativeName: "",
    IdentityNumber: "",
    ClientName: "",
    OfficeBlock: "",
    Address: "",
    City: "",
    CADate: "",
    PostalCode: "",
    SigningLocation: "",
    Witnesses: "",
    Witnesses2: "",
    Witnesses3: "",
    Witnesses4: "",
    SignatureJV: "",
    PartnerName: "",
    JVRegistrationNumber: "",
    HeadOfficeLocation: "",
    JointVentureName: "",
    JVClientName: "",
    RepresentativeNameA: "",
    RepresentativeNameB: "",
    LocationOfSigning: "",
    JVDate: "",
    JointVenturePartnerName: "",
    NameTitle: "",
    Spif: "",
    BOQ: "",
    ImplementationPlan: "",
    Letterofappointment: "",
    BankingStatements: "",
    ManagementAccounts: "",
    Insurance: "",
    CSD: "",
    CIDBDoc: "",
    NHBRC: "",
    TaxClearanceDoc: "",
    Vat: "",
    DepartLabor: "",
    personnel: "",
    AllDirectors: "",
    MartialSTatus: "",
    BBEECert: "",
    Companyregdocuments: "",
    companyprofile: "",
    ProofAddress: "",
    ApplicationForm: "",
    BaselineForm: "",
    CIPCForm: "",
    CreditagreementForm: "",
    DeclarationForm: "",
    JVForm: "",
    EligibilityCriteriaForm: "",
    CompanyDescriptionExtendedServices: "",
    CompanyDescriptionExtendedArea: "",
    BusinessStructureFamilyBusiness: "",
    BusinessStructureOwners: "",
    BusinessStructureRolesResponsibilities: "",
    MarketTarget: "",
    MarketOffTakersCurrent: "",
    MarketOffTakersPipeline: "",
    MarketStrategyCurrent: "",
    GovernanceSystems: "",
    GovernanceFinancialManagement: "",
    GovernanceAccounting: "",
    GovernanceHR: "",
    SkillsGap: "",
    ReasonForIntervention: "",
    BusinessChallenges: "",
    OwnerShortMediumLongGoals: "",
    OwnerGoalsSuccess: "",
    OwnerGoalsSuccessionPlan: "",
    ProjectManagerName: "",
    ProjectDescription: "",
    CAAddendum: "",
    JVAddendum: "",
  },
  ProjectsList: [],
});
