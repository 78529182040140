import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCol, IonRow, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { useParams } from 'react-router';

import { Descriptions, Row, Col, notification, message, Typography } from 'antd';
import { Card } from 'antd';
import { Switch } from 'antd';
import { Input } from 'antd';
import moment from 'moment';
import { PageHeader } from 'antd';
import axios from 'axios'
import { UIStore } from "../store/UIStore";
import { DatePicker, Space, Button } from 'antd';
import './Page2.css';
import React, { useState } from 'react';

const dateFormat = 'YYYY/MM/DD';
const { TextArea } = Input;
const BaselineReport: React.FC = (props: any) => {

  const { name } = useParams<{ name: string; }>();

  function onChange(checked: any) {
    console.log(`switch to ${checked}`);
  }

  async function SubmitBaseline() {
    console.log(CurrentProject);
    message
      .loading('Action in progress..', 5)
      .then(() => message.success('Submission Succeeded', 5));
    try {
      const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdateBaseline', CurrentProject).then((response) => {

        // message.success(response.data);
         console.log(response);
         props.history.push("/ProjectManager");


      });

    } catch (error) {
      console.error(error);
    }

  }


  const user = JSON.parse(localStorage.getItem("userobject") || '{}');
  const CurrentProject = UIStore.useState(s => s.CurrentProject);
  const { TextArea } = Input;
  const style = {paddingLeft: "2vw", paddingRight: "2vw"}
  const { Title } = Typography;


  

  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
  });

  

  useIonViewDidEnter(() => {
    axios.get('https://dev.northroom.co.za/api/spfund/legacy/users/compliance')
      .then(function (response) {

        //23 items



      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"

            title="Baseline report"

          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
   

          <p className="ion-text-center"><strong>SMME/Farm information overview</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Business Owner First Name</strong></p>
              <Input
                value={CurrentProject.BusinessOwnerFirstName}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BusinessOwnerFirstName = e.target.value})}}
                name="BusinessOwnerFirstName"
                placeholder="Business owner first name"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Business Owner Surname</strong></p>
              <Input
                value={CurrentProject.BusinessOwnerSurname}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BusinessOwnerSurname = e.target.value})}}
                name="BusinessOwnerSurname"
                placeholder="Business owner surname"
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Beneficiary ID Number</strong></p>
              <Input
                value={CurrentProject.BeneficiaryID}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BeneficiaryID = e.target.value})}}
                name="BeneficiaryID"
                placeholder="Beneficiary ID number"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Male/Female</strong></p>
              <Input
                value={CurrentProject.MaleFemale}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.MaleFemale = e.target.value})}}
                name="MaleFemale"
                placeholder="Male/Female"
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Under 36 Years Old (Yes/No)</strong></p>
              <Input
                value={CurrentProject.Under36}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.Under36 = e.target.value})}}
                name="Under36"
                placeholder="Under 36 years old (Yes/No)"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Race</strong></p>
              <Input
                value={CurrentProject.Race}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.Race = e.target.value})}}
                name="Race"
                placeholder="Race"
              />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Beneficiary Contact Details</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Cell/Tell Number</strong></p>
              <Input
                value={CurrentProject.CellTell}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.CellTell = e.target.value})}}
                name="CellTell"
                placeholder="Cell/Tell number"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Physical Address</strong></p>
              <Input
                value={CurrentProject.PhysicalAddress}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.PhysicalAddress = e.target.value})}}
                name="PhysicalAddress"
                placeholder="Physical address"
              />
            </IonCol>
          </Row>
          <p className="ion-text-center"><strong>Beneficiary Company Details</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Company Name</strong></p>
              <Input
                value={CurrentProject.CompanyName}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.CompanyName = e.target.value})}}
                name="CompanyName"
                placeholder="Company name"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>BBBEE Rating</strong></p>
              <Input
                value={CurrentProject.BbbeeRating}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BbbeeRating = e.target.value})}}
                name="BbbeeRating"
                placeholder="BBBEE rating"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>% Black Ownership</strong></p>
              <Input
                addonAfter="%"
                value={CurrentProject.BlackOwnership}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BlackOwnership = e.target.value})}}
                name="BlackOwnership"
                placeholder="% Black ownership"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>% Woman Ownership</strong></p>
              <Input
                addonAfter="%"
                value={CurrentProject.WomanOwnership}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.WomanOwnership = e.target.value})}}
                name="WomanOwnership"
                placeholder="% Female Ownership"
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>% Youth Ownership</strong></p>
              <Input
                addonAfter="%"
                value={CurrentProject.YouthOwnership}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.YouthOwnership = e.target.value})}}
                name="YouthOwnership"
                placeholder="% Youth ownership"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Company Contact Details</strong></p>
              <Input
                value={CurrentProject.CompanyContact}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.CompanyContact = e.target.value})}}
                name="CompanyContact"
                placeholder="Company contact details"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Business Sector of Operation</strong></p>
              <Input
                value={CurrentProject.BusinessSector}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BusinessSector = e.target.value})}}
                name="BusinessSector"
                placeholder="Business sector of operation"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Business Registration Date</strong></p>
              <Input
                value={CurrentProject.BusinessRegistrationDate}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BusinessRegistrationDate = e.target.value})}}
                disabled
                name="BusinessRegistrationDate"
                placeholder="Business registration date"
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Trading Start Date</strong></p>
              <Input
                value={CurrentProject.TradingStartDate}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.TradingStartDate = e.target.value})}}
                disabled
                name="TradingStartDate"
                placeholder="Trading start date"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Classification</strong></p>
              <Input
                value={CurrentProject.Classification}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.Classification = e.target.value})}}
                name="Classification"
                placeholder="Classification"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Services</strong></p>
              <Input
                value={CurrentProject.Services}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.Services = e.target.value})}}
                name="Services"
                placeholder="Services"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>GPS Coordinates</strong></p>
              <Input
                value={CurrentProject.GPSCoordinates}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.GPSCoordinates = e.target.value})}}
                name="GpsCoordinates"
                placeholder="GPS coordinates"
              />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Prior to joining the programme</strong></p>


          <Row>
            <IonCol class="ion-padding">
              <p><strong>Annual Revenue for the Most Recent Financial Period</strong></p>
              <Input
                value={CurrentProject.AnnualRevenue}
              type="number"
              prefix="R"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.AnnualRevenue = e.target.value})}}
                name="AnnualRevenue"
                placeholder="Annual Revenue"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Method of Funding for Business</strong></p>
              <Input
                value={CurrentProject.FundingMethod1}
                type="text"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.FundingMethod1 = e.target.value})}}
                name="FundingMethod1"
                placeholder="Funding Method"
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Number of Permanent Employees</strong></p>
              <Input
                value={CurrentProject.PermanentJobs}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.PermanentJobs = e.target.value})}}
                name="PermanentJobs"
                placeholder="Number of permanent employees"
              />
            </IonCol>
           
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Number of Short Temporary Jobs in the Business in the Past Year</strong></p>
              <Input
                value={CurrentProject.TemporaryJobs}
                type="number"
                onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.TemporaryJobs = e.target.value})}}
                name="TemporaryJobs"
                placeholder="Temporary Jobs"
              />
            </IonCol>
            
          </Row>

          <br></br>

          <div style ={style}>
          <IonRow>
            <IonCol className="ion-text-center" style = {{textAlign: "center"}}>
            <Title level={4}>Company Description</Title>
            <TextArea 
              rows={4} 
              placeholder="Services" 
              value={CurrentProject.CompanyDescriptionExtendedServices} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.CompanyDescriptionExtendedServices = e.target.value})}}
            />
            
            </IonCol>
          </IonRow>
          <br></br>
          <Row>
            <TextArea 
              rows={4} 
              placeholder="Area in which the business operates." 
              value={CurrentProject.CompanyDescriptionExtendedArea} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.CompanyDescriptionExtendedArea = e.target.value})}}
              />
          </Row>
          </div>

          <br></br>

          <div style ={style}>
          <IonRow>
            <IonCol className="ion-text-center" style = {{textAlign: "center"}}>
            <Title level={4}>Business Ownership Structure</Title>
            <TextArea 
              rows={4} 
              placeholder="Is this a family-run business? Which family members and their profiles (i.e. age, gender, educations, etc.)"
              value={CurrentProject.BusinessStructureFamilyBusiness} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BusinessStructureFamilyBusiness = e.target.value})}} 
            />
            </IonCol>
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
              rows={4} 
              placeholder="How many owners? Shareholding?" 
              value={CurrentProject.BusinessStructureOwners} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BusinessStructureOwners = e.target.value})}} 
            />
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
              rows={4} 
              placeholder="Roles & responsibilities."
              value={CurrentProject.BusinessStructureRolesResponsibilities} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BusinessStructureRolesResponsibilities = e.target.value})}} 
             />
          </IonRow>
          </div>
          <br></br>

          <div style ={style}>
          <IonRow>
            <IonCol className="ion-text-center" style = {{textAlign: "center"}}>
            <Title level={4}>Market</Title>
            <TextArea 
              rows={4} 
              placeholder="Primary target market." 
              value={CurrentProject.MarketTarget} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.MarketTarget = e.target.value})}} />
            </IonCol>
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
              rows={4} 
              placeholder="Current main off-takers for services (state if informal/formal)." 
              value={CurrentProject.MarketOffTakersCurrent} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.MarketOffTakersCurrent = e.target.value})}} />
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
              rows={4} 
              placeholder="Off-takers in the pipeline (i.e. projects)." 
              value={CurrentProject.MarketOffTakersPipeline} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.MarketOffTakersPipeline = e.target.value})}} />
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
              rows={4} 
              placeholder="Does the business have a current marketing/new business development strategy? Is it being implemented?"
              value={CurrentProject.MarketStrategyCurrent} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.MarketStrategyCurrent = e.target.value})}} />
          </IonRow>
          </div>
          <br></br>

          <div style ={style}>
          <IonRow>
            <IonCol className="ion-text-center" style = {{textAlign: "center"}}>
            <Title level={4}>Governance Structures</Title>
            <TextArea 
              rows={4} 
              placeholder="Business Processes/Systems in Place." 
              value={CurrentProject.GovernanceSystems} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.GovernanceSystems = e.target.value})}}
            />
            </IonCol>
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
              rows={4} 
              placeholder="Financial management." 
              value={CurrentProject.GovernanceFinancialManagement} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.GovernanceFinancialManagement = e.target.value})}}/>
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
              rows={4} 
              placeholder="Accounting software."
              value={CurrentProject.GovernanceAccounting} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.GovernanceAccounting = e.target.value})}} />
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
              rows={4} 
              placeholder="Formal HR procedures."
              value={CurrentProject.GovernanceHR} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.GovernanceHR = e.target.value})}}/>
          </IonRow>
          </div>
          <br></br>

          <div style ={style}>
          <IonRow>
            <IonCol className="ion-text-center" style = {{textAlign: "center"}}>
            <Title level={4}>Skills Gap</Title>
            <TextArea 
              rows={4} 
              placeholder="Highlight the current skills gap of the owner/owners and employees." 
              value={CurrentProject.SkillsGap} 
              onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.SkillsGap = e.target.value})}}
            />
            </IonCol>
          </IonRow>
          </div>
          <br></br>

          <div style ={style}>
          <IonRow>
            <IonCol className="ion-text-center" style = {{textAlign: "center"}}>
            <Title level={4}>Reason for Participating in the Intervention</Title>
            <TextArea 
            rows={4} 
            placeholder="Provide reasons for the beneficiary wanting to participate in the intervention." 
            value={CurrentProject.ReasonForIntervention} 
            onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.ReasonForIntervention = e.target.value})}}
            />
            </IonCol>
          </IonRow>
          </div>
          <br></br>

          <div style ={style}>
          <IonRow>
            <IonCol className="ion-text-center" style = {{textAlign: "center"}}>
            <Title level={4}>Key Business Challenges</Title>
            <TextArea 
            rows={4} 
            placeholder="Identify the challenges that the business currently faces." 
            value={CurrentProject.BusinessChallenges} 
            onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.BusinessChallenges = e.target.value})}}
            />
            </IonCol>
          </IonRow>
          </div>
          <br></br>

          <div style ={style}>
          <IonRow>
            <IonCol className="ion-text-center" style = {{textAlign: "center"}}>
            <Title level={4}>Owner Goals</Title>
            <TextArea 
            rows={4} 
            placeholder="Identify the targets the owner has set out for the business over the short, medium and long term." 
            value={CurrentProject.OwnerShortMediumLongGoals} 
            onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.OwnerShortMediumLongGoals = e.target.value})}}
            />
            </IonCol>
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
            rows={4} 
            placeholder="What does success look like?"
            value={CurrentProject.OwnerGoalsSuccess} 
            onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.OwnerGoalsSuccess = e.target.value})}}
            />
          </IonRow>
          <br></br>
          <IonRow>
            <TextArea 
            rows={4} 
            placeholder="Include information on succession planning."
            value={CurrentProject.OwnerGoalsSuccessionPlan} 
            onChange={(e:any)=>{   UIStore.update(s => {s.CurrentProject.OwnerGoalsSuccessionPlan = e.target.value})}}
            />
          </IonRow>
          </div>
          <br></br>
          

          <IonRow>
            <IonCol class="ion-padding">
            <Button size={"large"} type="primary" onClick={() => { SubmitBaseline(); }}>
                     Submit Baseline
                 </Button>
            </IonCol>
          </IonRow>
       
      </IonContent>
    </IonPage>


  );
};

export default BaselineReport;