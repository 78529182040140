import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave
} from '@ionic/react';
import { useParams } from 'react-router';

import { Descriptions, Row, Col, notification, List, Avatar, Button, Steps } from 'antd';
import { Card } from 'antd';
import { Switch, Radio } from 'antd';
import { Input } from 'antd';
import { PlusOutlined, ProjectOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { message, Tag } from 'antd';
import { DatePicker, Space, } from 'antd';
import './Page2.css';
import { PageHeader } from 'antd';
import React, { useState } from 'react';
import logo from '../pages/images/image6.jpg';

import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas';
import { DownloadOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
const dateFormat = 'YYYY/MM/DD';

const CICApprovalStatus: React.FC = (props: any) => {
  const { Step } = Steps;
  const [commentbox1, setShowcommentbox1] = useState(true);
  const [commentbox2, setShowcommentbox2] = useState(true);
  const [commentbox3, setShowcommentbox3] = useState(true);
  const [commentbox4, setShowcommentbox4] = useState(true);
  const [commentbox5, setShowcommentbox5] = useState(true);
  const [commentbox6, setShowcommentbox6] = useState(true);
  const [commentbox7, setShowcommentbox7] = useState(true);
  const [commentbox8, setShowcommentbox8] = useState(true);
  const [commentbox9, setShowcommentbox9] = useState(true);
  const [commentbox10, setShowcommentbox10] = useState(true);
  const [commentbox11, setShowcommentbox11] = useState(true);
  const [data, setdata] = useState<any[]>([])

  const { TextArea } = Input;
  const { name } = useParams<{ name: string; }>();
  var sigCanvas: any = ({});
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }



  function CheckObject() {
    const isEmpty = !Object.values(ApplicationObject).some(x => (x !== null && x !== ''));
    console.log(ApplicationObject);
    if (isEmpty === false) {
      notification['error']({
        message: 'Error',
        description:
          'All fields must be completed',
      });
    }
  }

  async function SubmitApplication() {

    message
      .loading('Action in progress..', 5)
      .then(() => message.success('Submission Succeeded', 5));
    try {
      const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/submitapplicationScreen', ApplicationObject).then((response) => {

        // message.success(response.data);
        // console.log(response);
        props.history.push("/CreditAgreement");


      });

    } catch (error) {
      message.error(error);
    }

  }

  const [projects, setprojects] = useState<any[]>([])

  const user = JSON.parse(localStorage.getItem("userobject") || '{}');
  const [ApplicationObject, setState] = useState({

    userid: "",
    SignatureApplication: "",
    Location: "",
    Date: "",
    Signatory: "",
    Capacity: "",
    CIBD: "",
    Ownership: "",
    ApplicationParticipation: "",
    MinimumWage: "",
    RingFenced: "",
    DevelopmentPlan: "",
    Training: "",
    Experience: "",
    DevelopmentAgreement: "",
    Assesment: "",
    MaterialsAgreement: "",
    ProjectName: "",
  });



  useIonViewDidEnter(() => {
    axios.get('https://dev.northroom.co.za/api/spfund/legacy/users/compliance')
    .then(function (response) {

      
      setdata(response.data);

  
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  });

  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
  });

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));

    setState(prevState => ({
      ...prevState,
      userid: user.id
    }));
  };
  const handleChangecheckbox = (e: any, name: any) => {

    setState(prevState => ({
      ...prevState,
      [name]: e
    }));

  };

  function onChangeDatePicker(DateWorkCompleted: any, dateString: any) {
    setState(prevState => ({
      ...prevState,
      Date: dateString
    }));
  }

  // function CreateProject() {

  // }



  const handleChangeSignature = (e: any, name: any) => {

    setState(prevState => ({
      ...prevState,
      [name]: e
    }));
  };

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState('Project Name');
  // const [modalText2, setModalText2] = React.useState('Some other input');

  const showModal = () => {
    setVisible(true);
  };

  function loadProject() {

  }

  const CreateProject = () => {
    setModalText('Processing Request');
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"

              title="Recommendation to CIC"

            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen >
        <IonRow>
          <IonCol >
            <Card>

              <Modal
                title="New Project"
                visible={visible}
                onOk={CreateProject}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
              >
                <p>{modalText}</p>
                <Input
                  value={ApplicationObject.ProjectName}
                  size="large"
                  placeholder=""
                  prefix={<ProjectOutlined />} />

                <br></br>
                <br></br>
                {/* <p>{modalText2}</p>

                <Input 
                size="large" 
                placeholder="" 
                prefix={<UserOutlined />} /> */}
              </Modal>

            </Card>
          </IonCol>
        </IonRow>

        <>

          <List
            dataSource={[
              {
                name: 'Application 1',

              },

            ]}
            bordered
            renderItem={item => (


              data.map((dataobject) =>
                <>

                  <List.Item

                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar src={logo} />
                      }
                      title={<p>Project Application ID:{dataobject.ProjectName}</p>}
                      description="In Progress Application"
                    />
                    <IonCol>

                      <Tag color="geekblue">Awaiting TEC Vote</Tag>

                    </IonCol>

                    <IonCol>
                      <Button type="primary" onClick={showModal} icon={<DownloadOutlined />}>
                        Download Investment Pack
                      </Button>
                    </IonCol>
                   



                  </List.Item>


                  
                </>
              )
            )}
          />



        </>

      </IonContent>
    </IonPage>


  );
};

export default CICApprovalStatus;
