import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  withIonLifeCycle,
  IonLabel,
  useIonViewDidEnter,
} from "@ionic/react";
import { useParams } from "react-router";

import { Descriptions, Row, Col, message, Upload } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import { UserOutlined, LockOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { menuController } from "@ionic/core";
import {
  DatePicker,
  Space,
  Button,
  Select,
  AutoComplete,
  Cascader,
} from "antd";

import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import logo from "../pages/images/image6.jpg";
import { AntDesignOutlined } from "@ant-design/icons";
import { RegisterAPI } from "../store/actions";
import { getcurrentproject } from "../store/actions";
import ImgCrop from "antd-img-crop";

const dateFormat = "YYYY/MM/DD";

const RegistrationPage: React.FC = () => {
  const [fileList, setFileList] = useState([]);

  const { Option } = Select;
  const { name } = useParams<{ name: string }>();
  const style = { paddingLeft: "130px" };
  const style2 = { paddingLeft: "40px" };
  const label = { paddingLeft: "10px" };
  const label2 = { paddingLeft: "25px" };
  const button = { paddingRight: "50px" };
  const img = { padding: "40px" };

  function onChange(info: any) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      setState((prevState) => ({
        ...prevState,
        ProfilePicture: info.file.response.path,
      }));
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");
  useIonViewDidEnter(() => {
    console.log(localStorage.getItem("userobject"));
  });

  const [ApplicationObject, setState] = useState({
    Email: "",
    Password: "",
    Password2: "",
    FullName: "",
    ID: "",
    ProfilePicture: "",
    cellphone: "",
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return file.type === "image/jpeg" && file.size / 1024 / 1024 < 2
      ? true
      : Upload.LIST_IGNORE;
  }

  const copyuseridentification = {
    name: "copyuseridentification",
    action: "https://dev.northroom.co.za/api/spfund/legacy/uploadimage/main",

    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setState((prevState) => ({
          ...prevState,
          ID: info.file.response.path,
        }));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonRow style={{ marginTop: "5%", marginLeft: "25%" }}>
          <IonCol>
            <div></div>
          </IonCol>
          <IonCol></IonCol>
          <IonCol>
            <Card style={{ width: 500 }}>
              <IonRow>
                <IonCol>
                  <div></div>
                </IonCol>

                <IonCol>
                  <img src={logo} alt="logo" />
                </IonCol>

                <IonCol>
                  <div></div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.Email}
                    type="email"
                    width="100px"
                    onChange={handleChange}
                    name="Email"
                    placeholder="Email"
                    prefix={<UserOutlined />}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.FullName}
                    type="email"
                    width="100px"
                    onChange={handleChange}
                    name="FullName"
                    placeholder="Full Name"
                    prefix={<UserOutlined />}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.cellphone}
                    type="number"
                    width="100px"
                    onChange={handleChange}
                    name="cellphone"
                    placeholder="Cellphone Number"
                    prefix={<UserOutlined />}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.Password}
                    type="password"
                    onChange={handleChange}
                    name="Password"
                    placeholder="Password"
                    prefix={<LockOutlined />}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <Input
                    size="large"
                    value={ApplicationObject.Password2}
                    type="password"
                    onChange={handleChange}
                    name="Password2"
                    placeholder="Confirm Password"
                    prefix={<LockOutlined />}
                  />
                </IonCol>
              </IonRow>

              <IonRow style={style}>
                <IonCol>
                  <IonCol>
                    <IonRow>
                      <label style={label}>Upload a copy of your ID</label>
                    </IonRow>
                    <br></br>
                    <Upload
                      beforeUpload={beforeUpload}
                      maxCount={1}
                      {...copyuseridentification}
                    >
                      <Button icon={<UploadOutlined />}>
                        Click to Upload Files
                      </Button>
                    </Upload>
                  </IonCol>
                </IonCol>
                <IonCol>
                  <IonCol>
                    <br></br>
                  </IonCol>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={style}>
                  <label style={label2}>Upload profile picture</label>
                  <br></br>
                  <br></br>
                  <IonCol style={style2}>
                    <ImgCrop>
                      <Upload
                        action="https://dev.northroom.co.za/api/spfund/legacy/uploadimage/main"
                        listType="picture-card"
                        onChange={onChange}
                        beforeUpload={beforeUpload}
                        style={img}
                        name={"profilepicture"}
                      >
                        {fileList.length < 5 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </IonCol>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol>
                  <Button
                    onClick={() => {
                      RegisterAPI(
                        ApplicationObject.Email,
                        ApplicationObject.Password,
                        ApplicationObject.Password2,
                        ApplicationObject
                      );
                    }}
                    block
                    type="primary"
                  >
                    Register
                  </Button>
                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
              </IonRow>
              <IonRow>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol style={button}>
                  <Button href="/LoginPage" block type="primary">
                    Login to Existing Account
                  </Button>
                </IonCol>

                <IonCol></IonCol>
                <IonCol></IonCol>
              </IonRow>
            </Card>
          </IonCol>
          <IonCol>
            <div></div>
          </IonCol>
          <IonCol>
            <div></div>
          </IonCol>
          <IonCol>
            <div></div>
          </IonCol>
          <IonCol>
            <div></div>
          </IonCol>
          <IonCol>
            <div></div>
          </IonCol>
          <IonCol>
            <div></div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default RegistrationPage;
