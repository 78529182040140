import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave
} from '@ionic/react';
import { useParams } from 'react-router';
import { getcurrentproject, setcurrentproject } from "../store/actions";
import { store } from "../store/store";

import { Descriptions, Row, Col, notification, Upload, Tooltip, Modal } from 'antd';
import { Card } from 'antd';
import { Switch } from 'antd';
import { Input } from 'antd';
import { Steps } from 'antd';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';

import { message } from 'antd';
import { DatePicker, Space, Button } from 'antd';
import './Page2.css';
import { PageHeader } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { UIStore } from "../store/UIStore";



const dateFormat = 'YYYY/MM/DD';
const { Step } = Steps;
const EligibilityCriteria: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState(s => s.CurrentProject);
  const [ApplicationObject, setState] = useState<any>({
    CIDB: false,
    Ownership: false,
    ApplicationParticipation: false,
    MinimumWage: false,
    RingFenced: false,
    DevelopmentPlan: false,
    Training: false,
    Experience: false,
    DevelopmentAgreement: false,
    Assesment: false,
    MaterialsAgreement: false,
    CIDBCommentBox: "",
    CIDBDoc: "",
    OwnershipCommentBox: "",
    ApplicationParticipationBox: "",
    MinimumWageBox: "",
    RingFencedBox: "",
    DevelopmentPlanBox: "",
    TrainingBox: "",
    ExperienceBox: "",
    DevelopmentAgreementBox: "",
    AssesmentBox: "",
    MaterialsAgreementBox: "",
  });
  const [commentbox1, setShowcommentbox1] = useState(true);
  const [commentbox2, setShowcommentbox2] = useState(true);
  const [commentbox3, setShowcommentbox3] = useState(true);
  const [commentbox4, setShowcommentbox4] = useState(true);
  const [commentbox5, setShowcommentbox5] = useState(true);
  const [commentbox6, setShowcommentbox6] = useState(true);
  const [commentbox7, setShowcommentbox7] = useState(true);
  const [commentbox8, setShowcommentbox8] = useState(true);
  const [commentbox9, setShowcommentbox9] = useState(true);
  const [commentbox10, setShowcommentbox10] = useState(true);
  const [commentbox11, setShowcommentbox11] = useState(true);



  function beforeUpload(file: any) {
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      message.error('You can only upload Pdf files!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('Document must smaller than 5MB!');
    }

    return (file.type === 'application/pdf' && file.size / 1024 / 1024 < 5) ? true : Upload.LIST_IGNORE;

  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    SubmitEligibility();
    props.history.push("/ApplicationForm")
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const user = JSON.parse(localStorage.getItem("userobject") || '{}');
  const propsCIDB = {
    name: 'propsCIDB',
    action: 'https://dev.northroom.co.za/api/spfund/legacy/uploadimage',
    headers: {
      authorization: user.id,
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);





      }
      if (info.file.status === 'done') {
        setState((prevState: any) => ({
          ...prevState,
          CIDBDoc: info.file.response.path
        }));
        UIStore.update(s => { s.CurrentProject.CIDBDoc = info.file.response.path })
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const { TextArea } = Input;
  const { name } = useParams<{ name: string; }>();
  var sigCanvas: any = ({});
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }


  const text = <span>Submit and proceed to the application form</span>;


  // async function EligibilityCriteria() {

  //   message
  //     .loading('Action in progress..', 5)
  //     .then(() => message.success('Submission Succeeded', 5));
  //   try {
  //     const request = await axios.post('https://dev.northroom.co.za/api/spfund/users/ECSUBMIT', ApplicationObject).then((response) => {

  //       message.success(response.data);
  //       // console.log(response);
  //       props.history.push("/BaselineReport");


  //     });

  //   } catch (error) {
  //     message.error(error);
  //   }

  // }

  useIonViewDidEnter(async () => {





    setState((prevState: any) => ({
      ...prevState,
      CIDB: CurrentProject.CIDB
    }));

    setShowcommentbox1(!CurrentProject.CIDB);

    setState((prevState: any) => ({
      ...prevState,
      CIDBCommentBox: CurrentProject.CIDBCommentBox
    }));

    setState((prevState: any) => ({
      ...prevState,
      Ownership: CurrentProject.Ownership
    }));

    setShowcommentbox2(!CurrentProject.Ownership);

    setState((prevState: any) => ({
      ...prevState,
      OwnershipCommentBox: CurrentProject.OwnershipCommentBox
    }));

    setState((prevState: any) => ({
      ...prevState,
      ApplicationParticipation: CurrentProject.ApplicationParticipation
    }));
    setShowcommentbox3(!CurrentProject.ApplicationParticipation);

    setState((prevState: any) => ({
      ...prevState,
      ApplicationParticipationBox: CurrentProject.ApplicationParticipationBox
    }));

    setState((prevState: any) => ({
      ...prevState,
      MinimumWage: CurrentProject.MinimumWage
    }));

    setShowcommentbox4(!CurrentProject.MinimumWage);

    setState((prevState: any) => ({
      ...prevState,
      MinimumWageBox: CurrentProject.MinimumWageBox
    }));

    setState((prevState: any) => ({
      ...prevState,
      RingFenced: CurrentProject.RingFenced
    }));

    setShowcommentbox5(!CurrentProject.RingFenced);

    setState((prevState: any) => ({
      ...prevState,
      RingFencedBox: CurrentProject.RingFencedBox
    }));

    setState((prevState: any) => ({
      ...prevState,
      DevelopmentPlan: CurrentProject.DevelopmentPlan
    }));

    setShowcommentbox6(!CurrentProject.DevelopmentPlan);

    setState((prevState: any) => ({
      ...prevState,
      DevelopmentPlanBox: CurrentProject.DevelopmentPlanBox
    }));

    setState((prevState: any) => ({
      ...prevState,
      Training: CurrentProject.Training
    }));

    setShowcommentbox7(!CurrentProject.Training);

    setState((prevState: any) => ({
      ...prevState,
      TrainingBox: CurrentProject.TrainingBox
    }));

    setState((prevState: any) => ({
      ...prevState,
      Experience: CurrentProject.Experience
    }));

    setShowcommentbox8(!CurrentProject.Experience);

    setState((prevState: any) => ({
      ...prevState,
      ExperienceBox: CurrentProject.ExperienceBox
    }));

    setState((prevState: any) => ({
      ...prevState,
      DevelopmentAgreement: CurrentProject.DevelopmentAgreement
    }));

    setShowcommentbox9(!CurrentProject.DevelopmentAgreement);

    setState((prevState: any) => ({
      ...prevState,
      DevelopmentAgreementBox: CurrentProject.DevelopmentAgreementBox
    }));

    setState((prevState: any) => ({
      ...prevState,
      Assesment: CurrentProject.Assesment
    }));

    setShowcommentbox10(!CurrentProject.Assesment);

    setState((prevState: any) => ({
      ...prevState,
      AssesmentBox: CurrentProject.Assesment
    }));

    setState((prevState: any) => ({
      ...prevState,
      MaterialsAgreement: CurrentProject.MaterialsAgreement
    }));

    setShowcommentbox11(!CurrentProject.MaterialsAgreement);

    setState((prevState: any) => ({
      ...prevState,
      MaterialsAgreementBox: CurrentProject.MaterialsAgreementBox
    }));



  });

  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
  });


  async function SubmitEligibility() {
    console.log(CurrentProject);
    console.log(ApplicationObject);
    message
      .loading('Action in progress..', 5)
      .then(() => message.success('Submission Succeeded', 5));
    try {
      const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate/Eligibility', CurrentProject).then((response) => {

        message.success(response.data);
        console.log(response);
        // props.history.push("/EligibilityCriteria");


      });

    } catch (error) {
      message.error(error);
    }

  }





  const handleChangecheckbox = (e: any, name: any) => {

    setState((prevState: any) => ({
      ...prevState,
      [name]: e
    }));

    if (name === "CIDB") {
      UIStore.update(s => {
        s.CurrentProject.CIDB = e;
      })
      setShowcommentbox1(!commentbox1);
    }

    if (name === "Ownership") {
      UIStore.update(s => {
        s.CurrentProject.Ownership = e;
      })
      setShowcommentbox2(!commentbox2);
    }

    if (name === "ApplicationParticipation") {
      UIStore.update(s => {
        s.CurrentProject.ApplicationParticipation = e;
      })
      setShowcommentbox3(!commentbox3);
    }

    if (name === "MinimumWage") {
      UIStore.update(s => {
        s.CurrentProject.MinimumWage = e;
      })
      setShowcommentbox4(!commentbox4);
    }

    if (name === "RingFenced") {
      UIStore.update(s => {
        s.CurrentProject.RingFenced = e;
      })
      setShowcommentbox5(!commentbox5);
    }

    if (name === "DevelopmentPlan") {
      UIStore.update(s => {
        s.CurrentProject.DevelopmentPlan = e;
      })
      setShowcommentbox6(!commentbox6);
    }

    if (name === "Training") {
      UIStore.update(s => {
        s.CurrentProject.Training = e;
      })
      setShowcommentbox7(!commentbox7);
    }

    if (name === "Experience") {
      UIStore.update(s => {
        s.CurrentProject.Experience = e;
      })
      setShowcommentbox8(!commentbox8);
    }

    if (name === "DevelopmentAgreement") {
      UIStore.update(s => {
        s.CurrentProject.DevelopmentAgreement = e;
      })
      setShowcommentbox9(!commentbox9);
    }

    if (name === "Assesment") {
      UIStore.update(s => {
        s.CurrentProject.Assesment = e;
      })
      setShowcommentbox10(!commentbox10);
    }

    if (name === "MaterialsAgreement") {
      UIStore.update(s => {
        s.CurrentProject.MaterialsAgreement = e;
      })
      setShowcommentbox11(!commentbox11);
    }
  };





  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              // onBack={() => null}
              title="Eligibility Criteria"

            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen >
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">
          <IonRow style={{ paddingLeft: "9%" }}>
            <IonCol class="ion-padding">
              <Steps current={0} status="error">
                <Step title="Eligibility Criteria" description="Complete Eligibility Criteria" />
                <Step title="Application Form" description="Incomplete" />
                <Step title="Compliance Checklist" description="Incomplete" />
                <Step title="CIC investment" description="Incomplete" />
              </Steps>
            </IonCol>
          </IonRow>
          <hr></hr>

          <IonRow className="ion-align-items-center ion-justify-content-center">
            <h6 style={{ marginLeft: "140px" }}><strong>SMME Selection Criteria</strong></h6>
          </IonRow>

          <hr></hr>


          <IonRow style={{ marginLeft: "140px" }}>
            <IonCol size="6">
              <Card style={{ width: 600 }}>
                <p>Are you registered with CIDB?</p>
                <Switch style={{ marginRight: "10px" }} checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.CIDB} onChange={e => { handleChangecheckbox(e, "CIDB") }} />
                <Upload beforeUpload={beforeUpload} maxCount={1} {...propsCIDB}>
                  <Button icon={<UploadOutlined />}>Click to Upload CIDB Registration File</Button>
                </Upload>
                <br></br>
                <br></br>
                {commentbox1 ? <TextArea name="CIDBCommentBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.CIDBCommentBox = e.target.value }) }} value={CurrentProject.CIDBCommentBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}

              </Card>
            </IonCol>

            <IonCol size="6">
              <Card style={{ width: 600 }}>
                <p>Are you a duly incorporated company with 51% black ownership?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.Ownership} onChange={e => { handleChangecheckbox(e, "Ownership") }} />
                <br></br>
                <br></br>
                {commentbox2 ? <TextArea name="OwnershipCommentBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.OwnershipCommentBox = e.target.value }) }} value={CurrentProject.OwnershipCommentBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>
          </IonRow>

          <hr></hr>

          <IonRow className="ion-align-items-center ion-justify-content-center">
            <h6 style={{ marginLeft: "140px" }}><strong>Potential to grow scale of work in terms of:</strong></h6>
          </IonRow>

          <hr></hr>

          <IonRow style={{ marginLeft: "140px" }}>
            <IonCol>
              <Card style={{ width: 600 }}>
                <p>Are you willing to submit an application to particiapte in the program?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.ApplicationParticipation} onChange={e => { handleChangecheckbox(e, "ApplicationParticipation") }} />
                <br></br>
                <br></br>
                {commentbox3 ? <TextArea name="ApplicationParticipationBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.ApplicationParticipationBox = e.target.value }) }} value={CurrentProject.ApplicationParticipationBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>

            <IonCol>
              <Card style={{ width: 600 }}>
                <p>Are you willing to employ people and pay them minimum industry wage?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.MinimumWage} onChange={e => { handleChangecheckbox(e, "MinimumWage") }} />
                <br></br>
                <br></br>
                {commentbox4 ? <TextArea name="MinimumWageBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.MinimumWageBox = e.target.value }) }} value={CurrentProject.MinimumWageBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>
          </IonRow>

          <IonRow style={{ marginLeft: "140px" }}>
            <IonCol>
              <Card style={{ width: 600 }}>
                <p>Are you willing to open a ring-fenced-account?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.RingFenced} onChange={e => { handleChangecheckbox(e, "RingFenced") }} />
                <br></br>
                <br></br>
                {commentbox5 ? <TextArea name="RingFencedBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RingFencedBox = e.target.value }) }} value={CurrentProject.RingFencedBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>



            <IonCol>
              <Card style={{ width: 600 }}>
                <p>Are you willing to participate in preparing a development plan for the business?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.DevelopmentPlan} onChange={e => { handleChangecheckbox(e, "DevelopmentPlan") }} />
                <br></br>
                <br></br>
                {commentbox6 ? <TextArea name="DevelopmentPlanBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.DevelopmentPlanBox = e.target.value }) }} value={CurrentProject.DevelopmentPlanBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>
          </IonRow>

          <IonRow style={{ marginLeft: "140px" }}>
            <IonCol>
              <Card style={{ width: 600 }}>
                <p>Are you willing to complete training when necessary?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.Training} onChange={e => { handleChangecheckbox(e, "Training") }} />
                <br></br>
                <br></br>
                {commentbox7 ? <TextArea name="TrainingBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.TrainingBox = e.target.value }) }} value={CurrentProject.TrainingBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>

            <IonCol>
              <Card style={{ width: 600 }}>
                <p>Do you have at least 1 year experience as a business or sole proprietor?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.Experience} onChange={e => { handleChangecheckbox(e, "Experience") }} />
                <br></br>
                <br></br>
                {commentbox8 ? <TextArea name="ExperienceBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.ExperienceBox = e.target.value }) }} value={CurrentProject.ExperienceBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>
          </IonRow>

          <IonRow style={{ marginLeft: "140px" }}>
            <IonCol>
              <Card style={{ width: 600 }}>
                <p>Are you willing to sign a development agreement for the business?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.DevelopmentAgreement} onChange={e => { handleChangecheckbox(e, "DevelopmentAgreement") }} />
                <br></br>
                <br></br>
                {commentbox9 ? <TextArea name="DevelopmentAgreementBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.DevelopmentAgreementBox = e.target.value }) }} value={CurrentProject.DevelopmentAgreementBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>

            <IonCol>
              <Card style={{ width: 600 }}>
                <p>Are you willing to go through assessment in order to particiapte in the program?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.Assesment} onChange={e => { handleChangecheckbox(e, "Assesment") }} />
                <br></br>
                <br></br>
                {commentbox10 ? <TextArea name="AssesmentBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.AssesmentBox = e.target.value }) }} value={CurrentProject.AssesmentBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>

          </IonRow>

          <IonRow style={{ marginLeft: "140px" }}>
            <IonCol>
              <Card style={{ width: 600 }}>
                <p>Are you willing to enter into a materials supply agreement where necessary. Including ceding income to the value of the funding?</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={CurrentProject.MaterialsAgreement} onChange={e => { handleChangecheckbox(e, "MaterialsAgreement") }} />
                <br></br>
                <br></br>
                {commentbox11 ? <TextArea name="MaterialsAgreementBox" onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.MaterialsAgreementBox = e.target.value }) }} value={CurrentProject.MaterialsAgreementBox} placeholder="Provide a reason/Ask for help?" rows={4} /> : null}
              </Card>
            </IonCol>

          </IonRow>

          <hr></hr>
          <IonRow className="ion-align-items-center ion-justify-content-center">

          </IonRow>
          <IonRow style={{ marginLeft: "140px" }}>


            <IonCol style={{ marginLeft: "530px" }} class="ion-marginLeft">
              {/* <Link to="/ApplicationForm"> */}
                <Tooltip placement="top" title={text}>
                  <Button size={"large"} type="primary" onClick={() => { showModal(); }}>
                    Submit
                </Button>
                  <Modal title="" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <p>Proceeding to the Application form. </p>
                    <p>You will be required to fill out business and shareholder details required by SP Fund to proceed.</p>
                  </Modal>
                </Tooltip>
              {/* </Link> */}
            </IonCol>
            <IonCol class="ion-marginLeft">
            </IonCol>
          </IonRow>
        </Space>
      </IonContent>
    </IonPage>


  );
};

export default EligibilityCriteria;
