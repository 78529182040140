import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCol, IonRow } from '@ionic/react';

import { useParams } from 'react-router';

import { Descriptions, Row, Col } from 'antd';
import { Card } from 'antd';
import { Switch, } from 'antd';
import { Input } from 'antd';
import moment from 'moment';
import { Dropdown, Menu, message, InputNumber, Upload } from 'antd';

import { DatePicker, Space, Button } from 'antd';
import './Page2.css';
import React, { useState } from 'react';
import { PageHeader } from 'antd';
import axios from 'axios'
import { notification } from 'antd';
const dateFormat = 'YYYY/MM/DD';
const { TextArea } = Input;

const ProjectBrief: React.FC = (props: any) => {

  const { name } = useParams<{ name: string; }>();
  const [count, setCount] = useState("");

  const openNotificationWithIcon = (type: any) => {

  };

  function onChange(checked: any) {
    console.log(`switch to ${checked}`);
  }

  function onChangeDatePicker(DateWorkCompleted: any, dateString: any) {
    setState(prevState => ({
      ...prevState,
      DateWorkCompleted: dateString
    }));
  }
  function CheckObject() {
    const isEmpty = !Object.values(ApplicationObject).some(x => (x !== null && x !== ''));
    console.log(ApplicationObject);
    if (isEmpty === false) {
      notification['error']({
        message: 'Error',
        description:
          'All fields must be completed',
      });
    }
  }

  function onChangeDatePicker2(CompletionDate: any, dateString: any) {
    setState(prevState => ({
      ...prevState,
      CompletionDate: dateString
    }));
  }

  async function SubmitProjectBrief() {

    message
      .loading('Action in progress..', 5)
      .then(() => message.success('Submission Succeeded', 5));
    try {
      const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/submitprojectbrief', ApplicationObject).then((response) => {

        // message.success(response.data);
        // console.log(response);
        props.history.push("/ComplienceChecklist");


      });

    } catch (error) {
      message.error(error);
    }

  }
  const user = JSON.parse(localStorage.getItem("userobject") || '{}');
  const [ApplicationObject, setState] = useState({
    userid: "",
    BusinessName: "",
    TradingName: "",
    AuthorizedPersonnel: "",
    Entity: "",
    RegistrationNumber: "",
    SARSRegistrationNumber: "",
    AnnualTurnover: "",
    CIDBGrade: "",
    BusinessAddress: "",
    POBox: "",
    TotalValueIncluding: "",
    TotalFundingRequired: "",
    TotalValue: "",
    FamilyOwned: "",
    NumberOfOwners: "",
    RolesAndResponsibilities: "",
    PrimaryBusiness: "",
    PrimaryTargetMarket: "",
    CurrentMainOffTakers: "",
    OffTakersPipeline: "",
    DevelopmentStrategy: "",
    BusinessProcesses: "",
    RegistrationName: "",
    FinancialManagement: "",
    AccountingSoftware: "",
    HRProcedures: "",
    DateWorkCompleted: "",
    SARSPin: "",
    ClientName: "",
    Project: "",
    ScopeOfWork: "",
    Value: "",
    NumberOfShareholders: "",
    GovRolesAndResponsibilities: "",
    GovPrimaryBusiness: "",
    ImmediateProjectGoals: "",
    CurrentProjectState: "",
    CompletionDate: "",
    Units: "",
    Activity: "",
    Subcontractor: "",
    NumberOfSubcontractors: "",
    WorkScope: "",
    Timeline: "",
    SMMEAssesmentStage: "",
    TrainingNeeds: "",
    ReasonsForParticipation: "",
    ReasonsForSelection: "",
    BeneficaryExpectations: "",
    BusinessChallenges: "",
    BusinessTargets: "",
    SuccesVision: "",
    SuccessionPlanning: "",
    BeneficaryType: "",
    Location: "",
    NumberOfUnits: "",
    Deliverables: "",
    FundingInstrument: "",
    SubcontractorsSummary: "",
    Milestones: "",
    EstimatedTimeline: "",
    Progress: "",
  });
  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));

    setState(prevState => ({
      ...prevState,
      userid: user.id
    }));
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"

            title="Project brief"

          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Space direction="vertical">

          <Descriptions className="ion-text-center" title="Client Summary Brief"></Descriptions>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Business name</strong></p>
              <Input
                value={ApplicationObject.BusinessName}
                type="text"
                onChange={handleChange}
                name="BusinessName"
                placeholder="Business Name" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Trading name</strong></p>
              <Input
                value={ApplicationObject.TradingName}
                type="text"
                onChange={handleChange}
                name="TradingName"
                placeholder="Trading Name" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Name and surname of duly authorized personnel</strong></p>
              <Input
                value={ApplicationObject.AuthorizedPersonnel}
                type="text"
                onChange={handleChange}
                name="AuthorizedPersonnel"
                placeholder="Authorized Personnel" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Form of enity</strong></p>
              <Input
                value={ApplicationObject.Entity}
                type="text"
                onChange={handleChange}
                name="Entity"
                placeholder="Entity" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Company registration number</strong></p>
              <Input
                value={ApplicationObject.RegistrationNumber}
                type="text"
                onChange={handleChange}
                name="RegistrationNumber"
                placeholder="Registration Number" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Company Registered Name</strong></p>
              <Input
                value={ApplicationObject.RegistrationName}
                type="text"
                onChange={handleChange}
                name="RegistrationName"
                placeholder="Company Registered Name" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>SARS registration numbe</strong></p>
              <Input
                value={ApplicationObject.SARSRegistrationNumber}
                type="text"
                onChange={handleChange}
                name="SARSRegistrationNumber"
                placeholder="SARS Registration Number" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>SARS Tax Clearance Pin</strong></p>
              <Input
                value={ApplicationObject.SARSPin}
                type="text"
                onChange={handleChange}
                name="SARSPin"
                placeholder="SARS Tax Clearance Pin" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Business annual turnover</strong></p>
              <Input
                value={ApplicationObject.AnnualTurnover}
                type="text"
                onChange={handleChange}
                name="AnnualTurnover"
                placeholder="Annual Turnover" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>CIDB grade</strong></p>
              <Input
                value={ApplicationObject.CIDBGrade}
                type="text"
                onChange={handleChange}
                name="CIDBGrade"
                placeholder="CIDB Grade" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Business address</strong></p>
              <Input
                value={ApplicationObject.BusinessAddress}
                type="text"
                onChange={handleChange}
                name="BusinessAddress"
                placeholder="Business Address" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>PO Box address</strong></p>
              <Input
                value={ApplicationObject.POBox}
                type="text"
                onChange={handleChange}
                name="POBox"
                placeholder="PO Box Address" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Total contact value (VAT Incl.)</strong></p>
              <Input
                value={ApplicationObject.TotalValueIncluding}
                type="text"
                onChange={handleChange}
                name="TotalValueIncluding"
                placeholder="Total Value Including VAT" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Total funding required</strong></p>
              <Input
                value={ApplicationObject.TotalFundingRequired}
                type="text"
                onChange={handleChange}
                name="TotalFundingRequired"
                placeholder="Total Funding Required" />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Company background and description</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Total contact value (VAT Incl.)</strong></p>
              <Input
                value={ApplicationObject.TotalValue}
                type="text"
                onChange={handleChange}
                name="TotalValue"
                placeholder="TotalValue" />
            </IonCol>
          </Row>
          <p className="ion-text-center"><strong>Business ownership structure</strong></p>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Is this a family-run business? Which family members and thier profiles (i.e. age etc)</strong></p>
              <Input
                value={ApplicationObject.FamilyOwned}
                type="text"
                onChange={handleChange}
                name="FamilyOwned"
                placeholder="Family Owned" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>How many owners?</strong></p>
              <Input
                value={ApplicationObject.NumberOfOwners}
                type="text"
                onChange={handleChange}
                name="NumberOfOwners"
                placeholder="Number Of Owners" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Roles and responsibilties </strong></p>
              <Input
                value={ApplicationObject.RolesAndResponsibilities}
                type="text"
                onChange={handleChange}
                name="RolesAndResponsibilities"
                placeholder="Roles And Responsibilities" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Primary business</strong></p>
              <Input
                value={ApplicationObject.PrimaryBusiness}
                type="text"
                onChange={handleChange}
                name="PrimaryBusiness"
                placeholder="Primary Business" />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Client summary brief</strong></p>
          <p className="ion-text-center"><strong>Market</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Primary target market</strong></p>
              <Input
                value={ApplicationObject.PrimaryTargetMarket}
                type="text"
                onChange={handleChange}
                name="PrimaryTargetMarket"
                placeholder="Primary Target Market" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Current main off-takers for goods/services</strong></p>
              <Input
                value={ApplicationObject.CurrentMainOffTakers}
                type="text"
                onChange={handleChange}
                name="CurrentMainOffTakers"
                placeholder=" Current Main Off Takers" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Off-takers in the pipeline</strong></p>
              <Input
                value={ApplicationObject.OffTakersPipeline}
                type="text"
                onChange={handleChange}
                name="OffTakersPipeline"
                placeholder="Off Takers Pipeline in the Pipeline" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Does the business have a current marketing/new business development stratergy? is it being implmented?</strong></p>
              <Input
                value={ApplicationObject.DevelopmentStrategy}
                type="text"
                onChange={handleChange}
                name="DevelopmentStrategy"
                placeholder="DevelopmentStrategy" />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Governance</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Business processes/systems in place</strong></p>
              <Input
                value={ApplicationObject.BusinessProcesses}
                type="text"
                onChange={handleChange}
                name="BusinessProcesses"
                placeholder="Business Processes" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Finacial management</strong></p>
              <Input
                value={ApplicationObject.FinancialManagement}
                type="text"
                onChange={handleChange}
                name="FinancialManagement"
                placeholder="Financial Management" />
            </IonCol>
          </Row>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Accounting software</strong></p>
              <Input
                value={ApplicationObject.AccountingSoftware}
                type="text"
                onChange={handleChange}
                name="AccountingSoftware"
                placeholder="Accounting Software" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Formal HR procedures</strong></p>
              <Input
                value={ApplicationObject.HRProcedures}
                type="text"
                onChange={handleChange}
                name="HRProcedures"
                placeholder="HR Procedures" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Previous work completed</strong></p>
              <DatePicker defaultValue={moment('2021/01/01', dateFormat)} format={dateFormat} onChange={onChangeDatePicker} />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Client</strong></p>
              <Input
                value={ApplicationObject.ClientName}
                type="text"
                onChange={handleChange}
                name="ClientName"
                placeholder="Client Name" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Project</strong></p>
              <Input
                value={ApplicationObject.Project}
                type="text"
                onChange={handleChange}
                name="Project"
                placeholder="Project" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Scope of work</strong></p>
              <Input
                value={ApplicationObject.ScopeOfWork}
                type="text"
                onChange={handleChange}
                name="ScopeOfWork"
                placeholder="Scope Of Work" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Value</strong></p>
              <Input
                value={ApplicationObject.Value}
                type="text"
                onChange={handleChange}
                name="Value"
                placeholder="Value" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>How many owners shareholding?</strong></p>
              <Input
                value={ApplicationObject.NumberOfShareholders}
                type="text"
                onChange={handleChange}
                name="NumberOfShareholders"
                placeholder="Number Of Shareholders" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Roles and responsibilties</strong></p>
              <Input
                value={ApplicationObject.GovRolesAndResponsibilities}
                type="text"
                onChange={handleChange}
                name="GovRolesAndResponsibilities"
                placeholder="Roles And Responsibilities" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Primary business</strong></p>
              <Input
                value={ApplicationObject.GovPrimaryBusiness}
                type="text"
                onChange={handleChange}
                name="GovPrimaryBusiness"
                placeholder="Primary Business" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Immediate project goal</strong></p>
              <Input
                value={ApplicationObject.ImmediateProjectGoals}
                type="text"
                onChange={handleChange}
                name="ImmediateProjectGoals"
                placeholder="Immediate Project Goals" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Current state of project</strong></p>
              <Input
                value={ApplicationObject.CurrentProjectState}
                type="text"
                onChange={handleChange}
                name="CurrentProjectState"
                placeholder="Current Project State" />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Completion schedule</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Date</strong></p>
              <DatePicker defaultValue={moment('2021/01/01', dateFormat)} format={dateFormat} onChange={onChangeDatePicker2} />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Units</strong></p>
              <Input
                value={ApplicationObject.Units}
                type="text"
                onChange={handleChange}
                name="Units"
                placeholder="Units" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Activity</strong></p>
              <Input
                value={ApplicationObject.Activity}
                type="text"
                onChange={handleChange}
                name="Activity"
                placeholder="Activity" />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Subcontractors</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Subcontractor</strong></p>
              <Input
                value={ApplicationObject.Subcontractor}
                type="text"
                onChange={handleChange}
                name="Subcontractor"
                placeholder="Subcontractor" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>No.of subcontractors</strong></p>
              <Input
                value={ApplicationObject.NumberOfSubcontractors}
                type="text"
                onChange={handleChange}
                name="NumberOfSubcontractors"
                placeholder="Number Of Subcontractors" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Work scope</strong></p>
              <Input
                value={ApplicationObject.WorkScope}
                type="text"
                onChange={handleChange}
                name="WorkScope"
                placeholder="WorkScope" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Timeline</strong></p>
              <Input
                value={ApplicationObject.Timeline}
                type="text"
                onChange={handleChange}
                name="Timeline"
                placeholder="Timeline" />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Beneficary</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Assessment stage of SMME</strong></p>
              <Input
                value={ApplicationObject.SMMEAssesmentStage}
                type="text"
                onChange={handleChange}
                name="SMMEAssesmentStage"
                placeholder="SMMEA ssesment Stage" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Training needs and skill GAP</strong></p>
              <Input
                value={ApplicationObject.TrainingNeeds}
                type="text"
                onChange={handleChange}
                name="TrainingNeeds"
                placeholder="Training Needs" />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Reasons for partcipating in the intervention</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Provide reasons for the beneficary wanting to participate in the intervention</strong></p>
              <TextArea
                value={ApplicationObject.ReasonsForParticipation}
                onChange={handleChange}
                name="ReasonsForParticipation"
                showCount maxLength={200} />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Provide reasons for the beneficary being selected for the intervention (as per the selection criteria)</strong></p>
              <TextArea
                value={ApplicationObject.ReasonsForSelection}
                onChange={handleChange}
                name="ReasonsForSelection"
                showCount maxLength={200} />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>What are the beneficary's expectations regarding thier participation in the intervention?</strong></p>
              <TextArea
                value={ApplicationObject.BeneficaryExpectations}
                onChange={handleChange}
                name="BeneficaryExpectations"
                showCount maxLength={200} />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Key business challenges</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>The challenges the business currently faces</strong></p>
              <TextArea
                value={ApplicationObject.BusinessChallenges}
                onChange={handleChange}
                name="BusinessChallenges"
                showCount maxLength={200} />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Owner goals (short, medium and long)</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>The targets the owner has set out for the business over a short, medium and long term.</strong></p>
              <TextArea
                value={ApplicationObject.BusinessTargets}
                onChange={handleChange}
                name="BusinessTargets"
                showCount maxLength={200} />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>What does success look like?</strong></p>
              <TextArea
                value={ApplicationObject.SuccesVision}
                onChange={handleChange}
                name="SuccesVision"
                showCount maxLength={200} />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Including information on succession planning</strong></p>
              <TextArea
                value={ApplicationObject.SuccessionPlanning}
                onChange={handleChange}
                name="SuccessionPlanning"
                showCount maxLength={200} />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Type of project Beneficiary - ADHOC/project beneficary</strong></p>
              <TextArea
                value={ApplicationObject.BeneficaryType}
                onChange={handleChange}
                name="BeneficaryType"
                showCount maxLength={200} />
            </IonCol>
          </Row>

          <p className="ion-text-center"><strong>Project summary</strong></p>

          <Row>
            <IonCol class="ion-padding">
              <p><strong>Location</strong></p>
              <Input
                value={ApplicationObject.Location}
                type="text"
                onChange={handleChange}
                name="Location"
                placeholder="Location" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>No. of units</strong></p>
              <Input
                value={ApplicationObject.NumberOfUnits}
                type="text"
                onChange={handleChange}
                name="NumberOfUnits"
                placeholder="Number Of Units" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Deliverables</strong></p>
              <Input
                value={ApplicationObject.Deliverables}
                type="text"
                onChange={handleChange}
                name="Deliverables"
                placeholder="Deliverables" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Funding instrument</strong></p>
              <Input
                value={ApplicationObject.FundingInstrument}
                type="text"
                onChange={handleChange}
                name="FundingInstrument"
                placeholder="Funding Instrument" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Subcontractors</strong></p>
              <Input
                value={ApplicationObject.SubcontractorsSummary}
                type="text"
                onChange={handleChange}
                name="SubcontractorsSummary"
                placeholder="Subcontractors Summary" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Milestones</strong></p>
              <Input
                value={ApplicationObject.Milestones}
                type="text"
                onChange={handleChange}
                name="Milestones"
                placeholder="Milestones" />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p><strong>Estimated timeline</strong></p>
              <Input
                value={ApplicationObject.EstimatedTimeline}
                type="text"
                onChange={handleChange}
                name="EstimatedTimeline"
                placeholder="Estimated Timeline" />
            </IonCol>
            <IonCol class="ion-padding">
              <p><strong>Progress</strong></p>
              <Input
                value={ApplicationObject.Progress}
                type="text"
                onChange={handleChange}
                name="Progress"
                placeholder="Progress" />
            </IonCol>
          </Row>
          <IonRow>
            <IonCol class="ion-padding">
              <Button size={"large"} onClick={() => { SubmitProjectBrief(); }} type="primary" >Next Steps</Button>
            </IonCol>
          </IonRow>
        </Space>
      </IonContent>
    </IonPage>


  );
};

export default ProjectBrief;