import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonItemDivider,
    withIonLifeCycle,
    IonLabel,
    useIonViewDidEnter
} from '@ionic/react';
import { useParams } from 'react-router';

import { Descriptions, Row, Col, } from 'antd';
import { Card } from 'antd';
import { Switch } from 'antd';
import { Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import moment from 'moment';
import { menuController } from "@ionic/core";
import { DatePicker, Space, Button, Select, AutoComplete, Cascader } from 'antd';
import './LoginPage.css';
import { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import logo from '../pages/images/image6.jpg';
import { AntDesignOutlined } from '@ant-design/icons';
import { LoginAPI } from "../store/actions";


const dateFormat = 'YYYY/MM/DD';
const LoginPage: React.FC = () => {
    const { Option } = Select;
    const { name } = useParams<{ name: string; }>();

    function onChange(unchecked: any) {
        console.log(`switch to ${unchecked}`);
    }


    function checklogin() {
        LoginAPI(ApplicationObject.Email, ApplicationObject.Password);
    }

    // useIonViewDidEnter(async () => {
    //     setTimeout(() => menuController.close(), 250);
    // });

    const [ApplicationObject, setState] = useState({
        Email: "",
        Password: "",
    });

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    return (
        <IonPage>


            <IonContent fullscreen >



                <IonRow style={{ marginTop: "10%", marginLeft: "25%" }}>
                    <IonCol>
                        <div>

                        </div>

                    </IonCol>
                    <IonCol>


                    </IonCol>
                    <IonCol>



                        <Card style={{ width: 500, }}>
                            <IonRow>

                                <IonCol>
                                    <div>

                                    </div>

                                </IonCol>

                                <IonCol>
                                    <img src={logo} alt="logo" />

                                </IonCol>


                                <IonCol>
                                    <div>

                                    </div>

                                </IonCol>


                            </IonRow>
                            <IonRow>





                                <IonCol>
                                    <Input size="large"
                                        value={ApplicationObject.Email}
                                        type="email"
                                        onChange={handleChange}
                                        name="Email"
                                        placeholder="Email"
                                        prefix={<UserOutlined />} />
                                </IonCol>

                                </IonRow>
                                <IonRow>
                                <IonCol>
                                    <Input size="large"

                                        value={ApplicationObject.Password}
                                        type="password"
                                        onChange={handleChange}
                                        name="Password"
                                        placeholder="Password"

                                        prefix={<LockOutlined />} />
                                </IonCol>
                                </IonRow>
                            
                            <IonRow>
                                <IonCol>

                                </IonCol>
                                <IonCol>

                                </IonCol>
                                <IonCol>

                                </IonCol>
                                <IonCol>
                                    <Button onClick={() => { checklogin() }} block type="primary">Login</Button>
                                </IonCol>
                                <IonCol>

                                </IonCol>
                                <IonCol>

                                </IonCol>
                                <IonCol>

                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>

                                </IonCol>
                                <IonCol>

                                </IonCol>
                                <IonCol>

                                </IonCol>
                                <IonCol>
                                    <Button href="/RegistrationPage" block type="primary">Register</Button>
                                </IonCol>
                                <IonCol>

                                </IonCol>
                                <IonCol>

                                </IonCol>
                                <IonCol>

                                </IonCol>
                            </IonRow>
                        </Card>



                    </IonCol>
                    <IonCol>
                        <div>

                        </div>

                    </IonCol>
                    <IonCol>
                        <div>

                        </div>

                    </IonCol>
                    <IonCol>
                        <div>

                        </div>

                    </IonCol>
                    <IonCol>
                        <div>

                        </div>

                    </IonCol>
                    <IonCol>
                        <div>

                        </div>

                    </IonCol>
                    <IonCol>
                        <div>

                        </div>

                    </IonCol>

                </IonRow>




            </IonContent>
        </IonPage>


    );
};

export default LoginPage;