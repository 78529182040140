import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import { useParams } from "react-router";

import { Descriptions, Row, Col, notification, List, Avatar } from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { message, Tag, Steps } from "antd";
import { DatePicker, Space, Button, Modal } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
const dateFormat = "YYYY/MM/DD";

const TECVoting: React.FC = (props: any) => {
  const [vote, setVote] = useState("Unvoted");
  const [data, setdata] = useState<any[]>([]);
  const { Step } = Steps;
  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const [comment, setComment] = useState("");

  const [recordOfDecision, setRecordOfDecision] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isModal2Visible, setIsModal2Visible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal2 = (myVote: any) => {
    setIsModal2Visible(true);
    setVote(myVote);
  };

  const handleOk2 = () => {
    setIsModal2Visible(false);
  };

  const handleCancel2 = () => {
    setIsModal2Visible(false);
  };

  const handleComment = (e: any) => {
    setComment(e.target.value);
  };

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function ApproveProject(object: any) {
    setRecordOfDecision(vote + "-" + comment);
    object.RECORDOFDECISION = vote + "-" + comment;

    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/markverifiedTEC",
          { object }
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/TEC"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
              // setRecordOfDecision(response.data.RECORDOFDECISION);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error) {
      message.error(error.data);
    }

    if (vote === "No") {
      try {
        await axios.post(
          "https://dev.northroom.co.za/api/spfund/users/messageuser/",
          {
            userid: user.id,
            message:
              user.FullName +
              " Your project has been declined, await contact from your project manager",
          }
        );
      } catch (error) {
        console.error(error);
      }
    } else if (vote === "Yes") {
      try {
        await axios.post(
          "https://dev.northroom.co.za/api/spfund/users/messageuser/",
          {
            userid: user.id,
            message:
              user.FullName +
              " Your project has passed the TEC vote and will now move onto the next phase",
          }
        );
      } catch (error) {
        console.error(error);
      }
    }

    console.log(object);
    handleOk2();
  }

  async function ExceptionReport(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/markverifiedTECrexception",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/TEC"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
              setRecordOfDecision(response.data.RECORDOFDECISION);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error) {
      message.error(error);
    }
  }
  async function DownloadPack(object: any) {}

  useIonViewWillEnter(() => {
    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/TEC"
      )
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
        setRecordOfDecision(response.data.RECORDOFDECISION);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  const handleSizeChange = (e: any) => {
    setVote(e.target.value);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="TEC Recommendations"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow>
          <IonCol class="ion-padding" size="12">
            <Steps current={2} status="error">
              <Step title="Eligibility Criteria" description="Complete" />
              <Step title="Application Form" description="Complete" />
              <Step
                title="Compliance Checklist"
                description="Complete Declaration"
              />
              <Step title="CIC investment" description="Incomplete" />
            </Steps>
          </IonCol>
        </IonRow>
        <>
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>Project Application ID:{dataobject.ProjectName}</p>
                      }
                      description="In Progress Application"
                    />

                    {/* <p>Registration with CIBD</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} onChange={e => { handleChangecheckbox(e, "CIBD") }} />
                <br></br>
                <br></br>
                {commentbox1 ? <TextArea placeholder="Provide a reason/Ask for help?" rows={4} /> : null} */}

                    <Button
                      onClick={() => {
                        DownloadPack(dataobject);
                      }}
                      type="primary"
                      disabled
                      icon={<DownloadOutlined />}
                    >
                      Download Project Summary
                    </Button>
                    <Row style={{ paddingLeft: "50px" }}>
                      <Col style={{ paddingRight: "50px", paddingTop: "1%" }}>
                        <div style={{ paddingBottom: "2%" }}>
                          <Button
                            type="primary"
                            onClick={showModal2}
                            style={{ width: "10vw" }}
                            disabled
                          >
                            Voting Comment
                          </Button>
                          <Modal
                            title="Provide Voting Comment"
                            visible={isModal2Visible}
                            onOk={() => {
                              ApproveProject(dataobject);
                            }}
                            onCancel={handleCancel2}
                          >
                            <TextArea
                              value={comment}
                              placeholder=""
                              rows={4}
                              onChange={(e) => {
                                handleComment(e);
                              }}
                            />
                          </Modal>
                        </div>

                        <div>
                          <Button
                            type="primary"
                            onClick={showModal}
                            style={{ width: "10vw" }}
                          >
                            Record of Decision
                          </Button>
                          <Modal
                            title="Record of Decision"
                            visible={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                          >
                            <TextArea
                              value={recordOfDecision}
                              placeholder=""
                              rows={4}
                            />
                          </Modal>
                        </div>
                      </Col>
                      <Col style={{ paddingRight: "50px", paddingTop: "10px" }}>
                        <p>Vote on Document Agreement</p>
                        <Radio.Group value={vote} onChange={handleSizeChange}>
                          <Radio.Button value="Unvoted">Unvoted</Radio.Button>
                          <Radio.Button
                            onClick={() => {
                              showModal2("Yes");
                            }}
                            value="Yes"
                          >
                            Yes
                          </Radio.Button>
                          <Radio.Button
                            onClick={() => {
                              showModal2("No");
                            }}
                            value="No"
                          >
                            No
                          </Radio.Button>
                        </Radio.Group>
                      </Col>
                    </Row>
                  </List.Item>
                </>
              ))
            }
          />
        </>
      </IonContent>
    </IonPage>
  );
};

export default TECVoting;
