// @ts-ignore
import * as remx from 'remx';
import { writeStorage } from '@rehooks/local-storage';


const initialState = {
  LoggedinState: false,
  Userobject: { Password:"",UserType:"",email:"",id:"",ID:"",ProfilePicture:"",FullName:"",cellphone:""},
 
};

const state = remx.state(initialState);
const getters = remx.getters({
  getuserobject() {
    return state.Userobject;
  },
  getcurrentproject() {
    console.log("GettingCurrentProjectStore:"+JSON.stringify(state.CurrentProject));
    return state.CurrentProject;
  },
  getloginstate() {
    return state.LoggedinState;
  },
});

const setters = remx.setters({
  setuser(userobject:any) {
    state.Userobject = userobject;
    writeStorage('userobject',userobject );
  },
  setcurrentProject(projectobject:any) {
    state.CurrentProject = projectobject;
    console.log("SettingCurrentProjectStore:"+state.CurrentProject);
    writeStorage('CurrentProject',JSON.stringify(projectobject) );

    
  },

  setloginstatus(loginstatus:any) {
    writeStorage('LoggedinState',loginstatus );
     state.LoggedinState = loginstatus;
 
  },
});

export const store = {
  ...setters,
  ...getters
};