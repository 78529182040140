import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  IonCard,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";

import { Descriptions, Row, Col, notification, Steps } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input, Table, Tag, Menu, Dropdown, Select } from "antd";
import { UserOutlined, DownOutlined, SaveOutlined } from "@ant-design/icons";
import moment from "moment";
import { message } from "antd";
import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { UIStore } from "../store/UIStore";

const PMAssign: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  const [projects, setProjects] = useState<any[]>([]);
  const [projectmanagers, setProjectManagers] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  const [selectedProjectManager, setselectedProjectManager] = useState({});
  //const [data,setData] = useState({});

  const { Step } = Steps;
  const { Option } = Select;

  const { name } = useParams<{ name: string }>();

  
  useIonViewDidEnter(() => {

    //load Projects without project managers
    axios
      .get("https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/projectmanagers")
      .then(function (response) {
        console.log(response.data)
        setProjects(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });


      //load all project managers
      axios
      .get("https://dev.northroom.co.za/api/spfund/legacy/users/projectmanagers/all")
      .then(function (response) {
        setProjectManagers(response.data);
        console.log(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  });

  const options:{} | any | undefined = [];
  
  projectmanagers.forEach((data) => {
    options.push(<Option key={data.id} label={data.FullName} value={data.id} title={data.FullName}>{data.FullName}</Option>);
  });

  useIonViewDidLeave(() => {
    console.log("Left PMAssign page");
  });

  async function SaveRole(record: any) {

    let tempGroup = {
      SelectedProject:record,
      ProjectManager:selectedProjectManager
    }
    
    message
      .loading("Action in progress..", 4)
      .then(() => message.success("Project manager successfully assigned", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate/setPM",
          tempGroup
        )
        .then((response) => {   
          console.log(response);
          setselectedProjectManager("");         
        });
    } catch (error) {
      console.log(error)  
      setselectedProjectManager("");
    }
    try {
      const user = JSON.parse(localStorage.getItem("userobject")||'{}');
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/users/messageuser/",
          { userid: user.id, message: user.FullName + " Your project has been assigned a project manager."}
        )
        .then((response2) => {
        });
    } catch (error) {
      console.error(error);
    }
   
    console.log(tempGroup);
    //console.log(selectedProjectManager);
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  function handleChangeSelect(value: any) {
    
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //let index = projectmanagers.findIndex(x=> x.id === record.id)
    //setselectedProjectManager(projectmanagers[index]);
  
    let index = projectmanagers.findIndex(x=> x.id === value.key)
    setselectedProjectManager(projectmanagers[index]);
    
    UIStore.update((s) => {
      s.CurrentProject.ProjectManagerName = value;
    });
  }

  const columns = [
    {
      title: "Project ID",
      dataIndex: "id",
    },
    {
      title: "Project Name",
      dataIndex: "ProjectName",
    },
    {
      title: "Project Manager",
      key: "action",
      render: (text: any, record: any) => (
        <Select
          style={{ width: "100%" }}
          placeholder="Select Individual"
          labelInValue
          defaultValue={[]}
          onChange={(value) => {
            handleChangeSelect(value);
          }}
          optionLabelProp="label"
        >
          {options}
        </Select>
      ),
    },
    {
      title: "Save",
      key: "save",
      render: (text: any, record: any) => (
        <Button
          onClick={() => {
            SaveRole(record);
          }}
          type="primary"
          icon={<SaveOutlined />}
        >
          Save Changes
        </Button>
      ),
    },
  ];
/*
  projectmanagers.map((PM)=>{
              <Option value={PM.FullName}>{PM.email}</Option>
            })
*/

  return (
    <IonPage>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              //onBack={() => null}
              title=" Assign Project Manager"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <Card>
          <IonRow>
            <IonCol>
              <Table
                 columns ={columns}
                 dataSource ={projects}
                  style={{ margin: "5%" }}
              />
               
            </IonCol>

          </IonRow>
        </Card>

      </IonContent>

    </IonPage>
  );
};

export default PMAssign;
