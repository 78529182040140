import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Modal,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import { DatePicker, Space } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
const dateFormat = "YYYY/MM/DD";

const ComplianceView: React.FC = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { Step } = Steps;

  const [data, setdata] = useState<any[]>([]);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function ApproveProject(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/compofficer",
          object
        )
        .then((response) => {
          console.log(response.data);
        });
    } catch (error) {
      message.error(error);
    }
  }
  async function ExceptionReport(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/markverifiedappofficerexception",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/appofficer"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error) {
      message.error(error);
    }
  }
  /*
  axios.get('https://dev.northroom.co.za/api/spfund/users/projectlistall/appofficer')
  .then(function (response) {
    console.log(response.data);
    setdata(response.data);

  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .then(function () {
    // always executed
  });
  */
  useIonViewWillEnter(() => {
    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/compofficer"
      )
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="Compliance Verification"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow style={{ paddingLeft: "7%" }}>
          <IonCol class="ion-padding" size="11">
            <Steps current={1} status="error">
              <Step
                title="Application Pack Verification"
                description="Complete"
              />
              <Step
                title="Application Pack Compliance"
                description="In Progress"
              />
              <Step
                title="Application Pack Verification"
                description="Application ERC review incomplete"
              />
              <Step
                title="Application Pack Submission to TEC"
                description="Pending TEC vote"
              />
            </Steps>
          </IonCol>
        </IonRow>

        <>
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={<p>Project Application ID:{dataobject.ProjectName}</p>}
                      description="Ready for Compliance Verification"
                    />
                  </List.Item>

                  <List.Item>
                    <IonRow>
                      <IonCol>
                        <Button
                          onClick={() => {
                            ApproveProject(dataobject);
                          }}
                          type="primary"
                        >
                          Mark as Verified
                        </Button>
                      </IonCol>

                      <IonCol>
                        {/* <Button
                          onClick={() => { ExceptionReport(dataobject); }}
                          type="primary">
                          Create Exception Report
                       </Button> */}

                        <Button type="primary" onClick={showModal}>
                          Create Exception Report
                        </Button>
                        <Modal
                          title="Exception Report Details"
                          visible={isModalVisible}
                          onOk={handleOk}
                          onCancel={handleCancel}
                        >
                          <p>Some contents...</p>
                          <p>Some contents...</p>
                          <p>Some contents...</p>
                        </Modal>
                      </IonCol>

                      <IonCol></IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol>
                        <Tag>
                          <a href={dataobject.EligibilityCriteriaForm} download>
                            Eligibility Criteria Download
                          </a>
                        </Tag>
                        <Tag>
                          <a href={dataobject.ApplicationForm} download>
                            Application Form Download
                          </a>
                        </Tag>
                        <Tag>
                          <a href={dataobject.DeclarationForm} download>
                            Declaration Form Download
                          </a>
                        </Tag>
                        <Tag>
                          <a href={dataobject.CIPCForm} download>
                            CPIC Report Download
                          </a>
                        </Tag>
                      </IonCol>
                    </IonRow>
                  </List.Item>
                </>
              ))
            }
          />
        </>
      </IonContent>
    </IonPage>
  );
};

export default ComplianceView;
