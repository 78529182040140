import { store } from "./store";
import axios from "axios";
import { message } from "antd";

export function LoginAPI(username: string, password: string) {
  message.loading({ content: "Loading..." });
  const API = "https://dev.northroom.co.za/api/spfund/legacy";
  axios
    .post(API + "/users/login", {
      email: username,
      password: password,
    })
    .then(function (response) {
      console.log();
      if (response.data.status == false) {
        message.error("Please check your Login credentials");
      }
      if (response.data.status == true) {
        setuserObject(response.data.user);
        Login();
        window.location.href = "/";
      }
    })
    .catch(function (error) {
      message.error(error);
    });

  store.setloginstatus(true);
}
export function RegisterAPI(
  username: string,
  password1: string,
  password2: string,
  entireobject: any
) {
  // console.log(entireobject);
  // return;

  if (password1 !== password2) {
    message.error("Passwords do not match!");
    return;
  }
  message.loading({ content: "Submitting Register..." });
  const API = "https://dev.northroom.co.za";
  axios
    .post(API + "/api/spfund/legacy/users/registeruser", {
      email: username,
      password: password1,
      ProfilePicture: entireobject.ProfilePicture,
      ID: entireobject.ID,
      FullName: entireobject.FullName,
      cellphone: entireobject.cellphone
    })
    .then(function (response) {
      console.log(response.data);
      setuserObject(response.data);

      message.success("Registration Successful");
      Login();

      window.location.href = "/";
    })
    .catch(function (error) {
      message.error(error);
    });
}
export function RegisterAPIRoles(entireobject: any) {
  // console.log(entireobject);
  // return;

  if (entireobject.password1 !== entireobject.password2) {
    message.error("Passwords do not match!");
    return;
  }

  message.loading({ content: "Submitting Register..." });
  const API = "https://dev.northroom.co.za";
  try {
    axios
      .post(API + "/api/spfund/users/legacy/registeruser", {
        email: entireobject.Email,
        password: entireobject.Password,
        ProfilePicture: entireobject.ProfilePicture,
        ID: entireobject.ID,
        FullName: entireobject.FullName,
        cellphone: entireobject.cellphone,
      })
      .then(function (response) {
        console.log(response.data);
        setuserObject(response.data);
        message.success("Registration Successful");
        
      });
  } catch (error) {
    message.error(error);
    
  }
  return true;
}

export function Login() {
  store.setloginstatus(true);
}
export function Logout() {
  store.setloginstatus(false);
  window.location.href = "/";
}
export function checklogin(): boolean {
  let storedLoginstate = localStorage.getItem("LoggedinState");
  console.log(storedLoginstate);

  if (storedLoginstate == "true") {
    return true;
  }
  return store.getloginstate();
}
export function getuserObject() {
  store.getuserobject();
}
export function getcurrentproject() {
  return store.getcurrentproject();
}
export function setcurrentproject(projectobject: any) {
  store.setcurrentProject(projectobject);
  console.log("SettingCurrentProject:" + projectobject);
}
export function setuserObject(userobject: any) {
  store.setuser(userobject);
}
