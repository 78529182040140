import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,

} from '@ionic/react';
import { useParams } from 'react-router';
import { UIStore } from "../store/UIStore";
import { Descriptions, Row, Col, notification } from 'antd';
import { Card } from 'antd';
import { Switch } from 'antd';
import { Input } from 'antd';
import { DatePicker, Space, Button } from 'antd';
import './Page2.css';
import moment from 'moment';
import { Steps, message } from 'antd';
import React, { useState } from 'react';
import { PageHeader } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import ReactDOM from 'react-dom'
import axios from 'axios'
import { setConstantValue } from 'typescript';
import TextArea from 'antd/lib/input/TextArea';
const dateFormat = 'YYYY/MM/DD';
const CreditAgreement: React.FC = (props: any) => {
  const CurrentProject = UIStore.useState(s => s.CurrentProject);


  const { name } = useParams<{ name: string; }>();
  var sigCanvas: any = ({});
  function onChange(checked: any) {
    console.log(`switch to ${checked}`);
  }
  const [signatureResult, setSignatureResult] = useState('');


  async function SubmitCreditAgreement() {
    
    message
      .loading("Action in progress..", 4)
      .then(() => message.success("Project manager successfully assigned", 5));
    
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdateCV",
          CurrentProject
        )
        .then((response) => {
          
          console.log(response);
                   
        });
    } catch (error) {
      
     
    }
    
  }



  const user = JSON.parse(localStorage.getItem("userobject") || '{}');





  useIonViewDidEnter(() => {
    console.log(CurrentProject);

  });

  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
  });



  const { Step } = Steps;

  const steps = [
    {
      title: '',
      content: <p style={{ padding: "20px" }}><h4 className="ion-text-center" ><strong>DEFINITIONS AND INTERPRETATION:</strong></h4><br></br>
    1.1	The following words and/or expressions shall have meanings as assigned to hereunder, unless stated otherwise or apparent from the context: <br></br><br></br>
    1.1.1 <strong>“Agreement”</strong> – means this Master document signed by the Parties and/or their duly authorized representatives, together with all Annexures hereto, which annexures must be read as if specifically incorporated herein irrespective of whether those Annexures have been signed by the parties or not and as amended from time to time;<br></br><br></br>
    1.1.2 <strong>“Bank Account”</strong> – means the ring-fenced account opened in the name of the Client for the sole purpose of executing the Contract in the manner as envisaged in terms of clause 5 below and regulated bank account shall have a corresponding meaning;<br></br><br></br>
    1.1.3 <strong>“The Contract”</strong> – means the agreement between the Client and any identified Main Contractor/Purchase Order Issuer/Off Take Issuer in terms of which the Client must supply goods and/or services to such identified Main Contractor/Purchase Order Issuer/Off Take Issuer;<br></br><br></br>
    1.1.4 <strong>“Disbursement, Drawdown & Repayment Schedule”</strong> – means a document incorporated into this agreement as Annexure “A”, or later as an addendum, reflecting the manner in which the Contract will be funded and the manner in which the profits and/or benefits, relating to the Contract will be divided;<br></br><br></br>
    1.1.5 <strong>“Purchase Order/ Off-take Agreement”</strong> – means an agreement between the Client and the Main Contractor/Purchase Order Issuer/Off Take Issuer in terms of which the Client will supply goods and/or services to the Main Contractor/Purchase Order Issuer/Off Take Issuer.<br></br><br></br>
    1.1.6 <strong>“Effective Date”</strong> – means the date on which the last signature is reflected in this agreement.<br></br><br></br>
    1.1.7 <strong>“Main Contractor/Purchase Order Issuer/Off Take Issuer”</strong> – means the Third Party with whom the Client has a Purchase Order or Off-take Agreement.<br></br><br></br>
    1.1.8 <strong>“Intellectual Property”</strong> – shall mean any information relating to the business or affairs of SP Fund (including but not limited to inventions, technical or non-technical data, formulae, patterns, compilations, programs, devises, methods, techniques, drawings, processes, financial data, financial plans, product plans and lists of actual or potential customers, suppliers and other business associates) which is acquired by the Client from, or produced by SP Fund, and vice versa, in the course and duration of this Agreement.<br></br><br></br>
    1.1.9	<strong>“SP Fund”</strong> – means Southern Palace Impact Fund (Pty) Ltd with registration number 2018/392779/07, collectively and/or separately should the context so require.<br></br><br></br>
    1.1.10 <br></br><br></br><strong style={{ marginBottom: "15px" }}>“The Client”</strong><Input
          value={CurrentProject.Client}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.Client = e.target.value }) }}
          name="Client"
          placeholder="Client"
        />Means with Registration Number:

<Input
          value={CurrentProject.RegistrationNumber}
          type="number"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RegistrationNumber = e.target.value }) }}
          name="RegistrationNumber"
          placeholder="Registration Number"
        />
  Duly Represented by: <Input
          value={CurrentProject.RepresentativeName}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.RepresentativeName = e.target.value }) }}
          name="RepresentativeName"
          placeholder="Representative Name"
        />

    with Identity Number:<Input
          value={CurrentProject.IdentityNumber}
          type="number"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.IdentityNumber = e.target.value }) }}
          name="IdentityNumber"
          placeholder="Identity Number"
        />
        <br></br><br></br>
  1.2	Any reference to any statute, regulation or other legislation shall be a reference to that statute, regulation or the legislation as at the Effective Date, and as amended or substituted from time to time.<br></br><br></br>
  1.3	If any provision in a definition is a substantive provision conferring a right or imposing an obligation on any Party, then notwithstanding that it is only in a definition, effect shall be given to that provision as it were a substantive provision in the body of this agreement.<br></br><br></br>
  1.4	Words importing the singular shall include a reference to the plural and vice versa.<br></br><br></br>
  1.5 A reference to one gender shall include any or all genders and a reference to the singular may be interpreted where appropriate as a reference to the plural and vice versa.<br></br><br></br>
  1.6 A reference to a person includes a human individual, a corporate entity and any organization which is managed or controlled as a unit.<br></br><br></br>
  1.7 	A reference to a person includes reference to that person’s successors, legal representatives, permitted assigns and any person to whom rights and obligations are transferred or pass as a result of a merger, division, reconstruction or other re-organization involving that person.<br></br><br></br>
  1.8	A reference to a paragraph or Annexure is to a paragraph or Annexure to this agreement unless the context otherwise requires. The Annexures form part of this agreement.<br></br><br></br>
  1.9	The headings to the paragraphs and Annexures to this agreement are inserted for convenience only and do not affect the interpretation.<br></br><br></br>
  1.10     Any agreement by any Party not to do or omit to do something includes an obligation not to allow some other person to do or omit to da that same thing.<br></br><br></br>
  1.11	Except where stated otherwise any obligation of any person arising from this agreement may be performed by any other person who is duly authorized.<br></br><br></br>
  1.12	A reference to the knowledge, information, belief, or awareness of any person shall be deemed to include the knowledge, information, belief or awareness that person would have if he made reasonable inquiries.<br></br><br></br>
  1.13   A reference to an act or regulation includes new law of substantially the same intent as the act or regulation referred to.<br></br><br></br>
  1.14	All money sums mentioned in this agreement are calculated including VAT unless otherwise stated.<br></br><br></br>
  1.15	This agreement is made only in the English language. If there is any conflict in meaning between the English language version of this agreement and any version or translation of this agreement in any other language, the English language version shall prevail.<br></br><br></br>
        <br></br>
        <h4 className="ion-text-center"><strong> COMMENCEMENT AND DURATION:</strong></h4><br></br>
  2.1 	This Agreement shall commence on the Effective Date and shall remain in force as the Master agreement until such a time this Agreement is terminated in accordance with the provisions of this Agreement.<br></br><br></br>
  2.2    If this Agreement is not terminated by either Party, this Agreement shall remain in effect and will regulate all future agreements between parties, subject to and provided that the Client agrees to the amended and/or subsequent Costing Sheets to be attached to this agreement for each project entered into hereafter.<br></br>

      </p>
    },
    {
      title: '',
      content: <p style={{ padding: "20px" }}><h4 className="ion-text-center"><strong>  OBLIGATIONS OF THE CLIENT:</strong></h4><br></br>
    3.1       Notwithstanding anything contrary stated in this Agreement, the Client shall remain liable for the performance of its obligations in terms of the Contract.<br></br><br></br>
    3.2 	The Client must perform the abovementioned obligation in terms of the Contract in a professional and workmanlike manner.<br></br><br></br>
    3.3	The Client must inform SP Fund of any variations and/or amendments to the provisions of the Contract, whether such variation and/or amendments have a material impact in relation to this Agreement or not.<br></br><br></br>
    3.4	The Client shall ensure that it, or the Client duly authorized representative, is available for meetings and/or conference calls at all reasonable times during office hours, to ensure that effect is given to the provisions of this agreement.<br></br><br></br>
    3.5 	The Client shall ensure that at all relevant times and for the duration of this agreement and all future projects they remain tax and VAT compliant and should they fail to maintain a compliant status they may be considered to be in breach of this agreement and SP Fund will be entitled to enforce its remedies under this agreement which will include but may not be limited to the cancellations of this agreement and a claim for damages.<br></br><br></br>
    3.6	The Client shall ensure that all equipment and or materials, required to fulfil its obligations in terms of the Contract are maintained and delivered to any site, if applicable, within the time and on the conditions stated in the Contract.<br></br><br></br>
    3.7     Should the Contract be cancelled and/or revoked due to the failure of the Client to fulfil its reasonable obligations in terms of the Contract and/or for any other reason, regardless of the reason for such termination, the Client shall remain liable for SP Fund’s Service Fee as contained in the Costing Sheet attached hereto as well as any and all disbursements effected by SP Fund, whether such disbursements is reflected on the Costing Sheet or not.<br></br><br></br>
        <h4 className="ion-text-center"><strong>  OBLIGATIONS OF SP FUND:</strong></h4><br></br>
    4.1       SP Fund will render the necessary support and integrated system solutions, including but not limited to, verification of the Purchase Order/Off-Take Agreement, sourcing, price and payment term negotiation with suppliers, payment to suppliers, project management of the project from start until payment is received from the Purchase Order Issuer, to enable the Client to fulfil all is obligations in terms of the Contract, provided that the Client acknowledges that it shall remain ultimately liable for the fulfilment of all obligations in terms of Contract.<br></br><br></br>
    4.2	Upon request from the Client, SP Fund shall provide the Client with a process management and administrative service, which fee will be included and defined in the Costing Sheet to be added to this agreement as an addendum where necessary.<br></br><br></br>
    4.3	SP Fund will empower the Client with the ability to accelerate and guarantee early settlement of its obligations for the purchase of goods and services from Suppliers and by so doing, SP Fund will attempt to negotiate discounts, on the Client’s behalf, with all Suppliers.<br></br><br></br>
    4.4	In the event that any discounts are negotiated by SP Fund for and on behalf of the Client, such discounts from Suppliers shall be for the sole benefit of the Client.<br></br><br></br>
    4.5	SP Fund will ensure that the loan is structured in such a manner as to ensure sustainability and profitability of the Client, including but not limited to concessionary interest rate of between prime less 4% to prime, payment holidays linked to the revenue flows from the Contract, and any other concessions that will ensure the Client gets maximum profit from the Contract.<br></br><br></br>
      </p>,
    },
    {
      title: '',
      content: <p style={{ padding: "20px" }}><h4 className="ion-text-center"><strong>  BANK ACCOUNT:</strong></h4><br></br><br></br>
    5.1     	The Client hereby acknowledges that it is, to give effect to this Agreement, imperative that a separate Bank Account, for purposes of receiving monies in terms of Contact, is opened with First National Bank; or, SP Fund is given access to the Bank Account into which the company revenues are received for the project.<br></br><br></br>
    5.2         The Client acknowledges that the Bank Account as envisaged in terms of clause 5.1 above, shall operate as a regulated Bank Account and utilized for the receipt of funds from the Main Contractor/Purchase Order Issuer/Off Take Issuer and the distribution of funds, in accordance with the Disbursement, Repayment and Drawdown Schedule (“the Schedule”) of the project, to the Client and SP Fund.<br></br><br></br>
    5.3          As the Bank Account is envisaged in this clause is a prerequisite for the delivery of any services by SP Fund to the Client, the Client agrees that the following shall apply with regard to the Bank Account.<br></br><br></br>
    5.3.1	The Client must sign all the relevant documents, as required by First National Bank, in terms of which a regulated bank account will be established for the sole purpose of receiving and distributing funds received by the Main Contractor/Purchase Order Issuer/Off Take Issuer in terms of the Contract; or the Client must add the SP Fund representative to the bank account, in which revenues of the company are received for the project.<br></br>
    5.3.2	The Client hereby acknowledges that no other transactions outside the agreed Contract related transactions may be done in the Bank Account without SP Fund’s authorization.<br></br><br></br>
    5.4        The Bank Account shall be then the only account in which the Client may receive any payment of Funds, whether such funds are for the payment of an invoice for services and or delivery for goods in relation to the Purchase Order/Off-take Agreement issued in terms of the contract.<br></br><br></br>
    5.5 	The Client hereby agrees that the only bank details which may appear on an invoice and/or any other payment instruction, for the payment of goods and/or services in relation to the Contract must be the details of the Bank Account opened in accordance with this clause.<br></br><br></br>
    5.6	The Client hereby acknowledges that any act and/or conduct by it and/or its duly authorized representative to change the bank details on the Purchase Order/Off-take Agreement and/or the other document and/or system of the Main Contractor/Purchase Order Issuer/Off Take Issuer to any other account as the Bank Account opened in terms of this clause, will be considered by SP Fund an unlawful action which action is taken to the detriment and/or potential detriment of SP Fund. SP Fund reserves their rights to report such conduct to the South African Police Service for further investigation. Such conduct will also constitute a breach of this agreement and SP Fund will be entitled at its sole discretion to cancel this agreement and enforce its remedies under this agreement against the Client and its sureties as the case may be.<br></br><br></br>
    5.7 In a case of a regulated Bank Account, the Client shall not be liable for payment of any bank charges in relation to the Bank Account and such bank charges shall be borne by SP Fund for the duration of this agreement, unless the Client elects to utilize the Bank Account in terms of clause 5.10 below.<br></br><br></br>
    5.8	In the event that non-related funds are paid into the regulated Bank Account established in terms of this clause, such funds must immediately be transferred into a different nominated bank account of the Client, together with any interest which may have accrued on such non-related funds.<br></br><br></br>
    5.9        Should the Client, wish to utilize the regulated Bank Account after the completion of the Contract and/or upon termination of this agreement, the Client shall become liable for the payment of all and any bank charges levied by First National Bank.<br></br><br></br>
    5.10     The Client acknowledges that, since SP Fund shall be liable for all costs related to the regulated Bank Account as per clause 5.8 above, all interest which may accrue, on funds related to this agreement, in relation to the Bank Account shall be for the sole benefit of SP Fund.<br></br><br></br>
    5.11 	The only payments to be effected from the Bank Account shall be the payments as specified in the Disbursement, Drawdown and Repayment Schedule attached hereto/or to be added hereto as an addendum, and/or other disbursements which may be necessary to give effect to the Contract, whether such payments are reflected in the Disbursement, Drawdown and Repayment Schedule or not.<br></br><br></br>
    5.12	 Should the Main Contractor/Purchase Order Issuer/Off Take Issuer, for any reason whatsoever, deposit any amount due in terms of this agreement into any other account than the Bank Account established in terms of this clause, the Client shall ensure that any such amounts are transferred to the Bank Account established in terms of this clause. The Client acknowledges that failure to transfer any amounts received by it into an account other than the Bank Account envisaged in this agreement is an intentional misrepresentation to the detriment and/or potential detriment of SP Fund and may be viewed as fraud or may be viewed as the intentional appropriation of funds amounting to theft. Such conduct will also constitute a breach of this agreement and SP Fund will be entitled at its sole discretion to cancel this agreement and enforce its remedies under this agreement.<br></br><br></br>
    5.13	The parties agree that this account can be utilized by the CIDB for the purposes of grading of the Client.<br></br><br></br>
        <h4 className="ion-text-center"><strong>  PAYMENT CONSIDERATION:</strong></h4><br></br><br></br>
    6.1 	The Client shall be liable for payment of the Payment Consideration towards SP Fund for support services rendered by SP Fund to the Client in terms of this agreement which Payment Consideration shall be calculated as follows:<br></br><br></br>
    6.1.1	 An agreed amount as reflected on the Disbursement, Drawdown & Repayment Schedule, which amount shall be inclusive of Value Added Tax, unless specifically stated otherwise.<br></br><br></br>
    6.1.2	The Parties hereby acknowledges that all charges, penalty interest and/or guarantees issued for and on behalf of the Client shall be Reflected in the Disbursement, Drawdown & Repayment Schedule, in as far as it may be reasonably foreseeable.<br></br><br></br>
    6.1.3	The Client, however, acknowledges that SP Fund may from time to time be obligated to incur other expenses and may be required to effect payment of other disbursements for and on behalf of the Client which are not reflected in the Disbursement, Drawdown & Repayment Schedule, and the Client hereby agree that such disbursements shall be recoverable from the Client, provided they were communicated to the Client by SP Fund beforehand.<br></br><br></br>
    6.1.4	The Client, furthermore, acknowledges that all payments as per the Disbursement, Drawdown & Repayment Schedule shall be effected on either due date reflected in the Schedule or the Completion date of the Contract, whichever occur first.<br></br><br></br>
        <h4 className="ion-text-center"><strong>  CONFIDENTIALITY:</strong></h4><br></br><br></br>
    7.1 The Client hereby acknowledges that, for the purposes of this Agreement, SP Fund will disclose certain Intellectual Property, which is reasonably required by the Parties for the execution of the Contract, which form the subject matter of this Agreement.<br></br><br></br>
    7.2 The Client agrees that, through such disclosure, no rights of whatever nature has been conferred upon it by receiving such Intellectual Property and is such Intellectual Property the sole property of SP Fund.<br></br><br></br>
    7.3	The Client may not disclose such Intellectual Property, to any third party and agrees that any such Intellectual Property shall only be utilized to give effect to the provisions of this agreement.<br></br><br></br>
    7.4	Should the Client disclose any Intellectual Property obtained from SP Fund to any Third Party, other as in accordance with this agreement, SP Fund may claim any such damages it may have suffered as a result of the Client’s breach.<br></br><br></br>
      </p>,
    },
    {
      title: '',
      content: <p style={{ padding: "20px" }}><h4 className="ion-text-center"><strong>   NO PARTNERSHIP OR AGENCY:</strong></h4><br></br>
    8.1	Should the Client represent that it and/or its duly authorized representative is an agent of SP Fund, such representation will be deemed a breach of a material term of this Agreement.<br></br><br></br>
    8.2	 Nothing contained in this Agreement shall be construed as a partnership and or joint venture between the Parties and, unless differently specified in this agreement no Party to this agreement is authorized to act as the agent/or representative of the other Party.<br></br><br></br>
        <h4 className="ion-text-center"><strong>   FORCE MAJEURE:</strong></h4><br></br>
    9.1	Neither Party shall be liable to the other Party for any delay and/or non-performance of any of this obligation in terms of this Agreement in the event and/or to the extent that such delay and/or non-performance is caused by an event of Force Majeure, as defined in terms of clause 9.2 below.<br></br><br></br>
    9.2	 Events of Force Majeure are events which is not caused by any act and/or omission of any Party which delays and/or prevents any party from proper performance in terms of this Agreement and which event is total out of the control and unforeseeable at the time of signing of this Agreement by such Party and, without limiting the generality of the aforesaid shall include War, Civil Unrest, Natural Disasters, Accidents, Fire and Industry wide lockouts and/or strikes.<br></br><br></br>
    9.3         Each Party shall inform the other Party, immediately, of the occurrence of any event of Force Majeure and the expected duration of such event, if reasonably possible.<br></br><br></br>
    9.4	Notwithstanding anything contrary contained on this agreement, the Client shall remain liable for the repayment of any capital, disbursements and/or interest, if applicable, SP Fund may have incurred by virtue of this agreement.<br></br><br></br>
        <h4 className="ion-text-center"><strong>   INSURANCE:</strong></h4><br></br>
    10.1	The Client agrees that it shall ensure that the necessary insurance, if any, is acquired and maintained in accordance with the relevant laws, including, but not limited to public liability insurance, which may be required from time to time.<br></br><br></br>
        <h4 className="ion-text-center"><strong>  ADDRESSES AND NOTICES:</strong></h4><br></br>
    11.1	For the purpose of this Agreement, including the giving of notices in terms hereof and serving of legal process, the Parties choose their domicilium citandi et executandi (“domicilium”) as follow:<br></br><br></br>
    11.1.1<br></br><br></br>	 SP Fund:<br></br>
    The Place<br></br>
    No. 1 Sandton Drive<br></br>
    Sandton<br></br>
    2196<br></br><br></br>
    11.1.2<br></br><br></br><Input
          value={CurrentProject.ClientName}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.ClientName = e.target.value }) }}
          name="ClientName"
          placeholder="Client Name"
        /> <br></br><br></br>
        <Input
          value={CurrentProject.OfficeBlock}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.OfficeBlock = e.target.value }) }}
          name="OfficeBlock"
          placeholder="Office Block"
        /><br></br><br></br>
        <Input
          value={CurrentProject.Address}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.Address = e.target.value }) }}
          name="Address"
          placeholder="Address"
        /><br></br><br></br>
        <Input
          value={CurrentProject.City}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.City = e.target.value }) }}
          name="City"
          placeholder="City"
        /><br></br><br></br>
        <Input
          value={CurrentProject.PostalCode}
          type="number"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.PostalCode = e.target.value }) }}
          name="PostalCode"
          placeholder="Postal Code"
        /><br></br><br></br>

11.2       A Party may at any time change its domicilium by notice in writing, provided that the new domicilium is in the Republic of South Africa and consists of and/or includes a physical address at which process can be served.<br></br><br></br>
11.3 	Any notice given in connection with this agreement may be delivered by hand; or be sent by prepaid registered post. Any notice or process deliver on any Party in connection with any matter or subject arising out of this agreement shall be deemed to have been delivered if handed to any responsible person at the domicilium chosen by any Party and it shall not be necessary to hand such process or notice to any Party personally.<br></br><br></br>
11.4     A notice given as set out above shall be presumed to have been duly delivered:<br></br><br></br>
11.4.1    On the day the notice has been delivered at the domicilium:<br></br><br></br>
11.4.2   On the day the notice has been delivered via Email and a successful delivery receipt has been obtained.<br></br><br></br>
11.4.3    On the 7th (SEVENTH) day from the date of posting including the date of posting if posted by prepaid registered post from within the Republic of South Africa.<br></br><br></br>
11.4.4	On the 21st (TWENTY-FIRST) day from the date of posting including the date of posting if posted by pre-paid registered post from any other Country other than the Republic of South Africa.<br></br>
      </p>,
    },
    {
      title: '',
      content: <p style={{ padding: "20px" }}><h4 className="ion-text-center"><strong>  CESSION AND ASSIGNMENT:</strong></h4><br></br>
    12.1        The Client hereby cedes future revenues to SP Fund to the maximum of the outstanding loan amount and interest until the loan and interest is fully repaid.<br></br><br></br>
    12.2 	The Client hereby irrevocably grants to SP Fund, with power of substitution full power and authority to recover in our name from all or any of the Client’s debtors, all amounts and all goods and other assets which the Client may be entitled to recover and or repossess and, if so determined by SP Fund, to institute action in our name against all or any of the Client’s debtors for the purpose of the said recoveries and to retain the proceeds recovered in the exercise of such powers on account of, and in reduction of whatsoever nature together with interest at the maximum rate permitted by law, together with legal costs as between attorney and own client incurred in the exercise of any such power.<br></br><br></br>
    12.3	 The Client agrees that this cession and pledge shall be in force and effect until all liability to SP Fund has been paid or otherwise discharged.<br></br><br></br>
    12.4	 Irrespective of whether the Client’s debtors have been notified of this cession, all sums of money which the Client directly collects from its debtors or any of them shall be collected and received by SP Fund in favor of SP Fund and shall such sums be received in the Bank Account created in terms of clause 5 above.<br></br><br></br>
    12.5	SP Fund shall be entitled at all times to inspect and/or remove any of the Client’s books of accounts, invoice and delivery books and other records of whatsoever nature relating to the Client’s debtors or otherwise, and to extract from them all such details and/or copies as SP Fund may in its sole and absolute discretion require.<br></br><br></br>
    12.6	The Client warrants that it is and will remain the sole beneficial owner of any and/or all rights envisaged in terms of clause 12.2 above, to the exclusion of all others and no person has an option and/or right of refusal over such rights.<br></br><br></br>
    12.7	The Client warrants that the cession in terms of this agreement and/or its fulfilment of its obligations in terms of this clause do not contravene any law, regulation and/or other contractual obligation it may have.<br></br><br></br>
    12.8 	The Client warrants that the rights ceded under this agreement have not been ceded, mortgaged under a notarial bond and/or in any other manner and/or otherwise disposed of or hypothecated.<br></br><br></br>
    12.9	The Client hereby renounce the benefits of the legal exceptions, non-causa debiti, errore calculi, revision of accounts, no value received and any other exceptions which night or could have been taken in law to the payment of the debtor’s indebtedness to the creditor aforesaid, with the full force, meaning and effect thereof, the Client acknowledge itself to be fully acquainted.<br></br><br></br>
    12.10    	The provisions of this Agreement shall not be ceded and/or assigned by the Client to any Third Party other than in accordance with this Agreement.<br></br><br></br>
        <h4 className="ion-text-center"><strong>    PROOF OF INDEBTEDNESS:</strong></h4><br></br>

    13.1    The Client hereby acknowledges that, this agreement and the signed Schedule, shall be prima facie proof of the Client’s indebtedness to SP Fund and in the amount as reflected thereon.<br></br><br></br>
    13.2    SP Fund shall not be required to prove appointment of and/or signature of the person who signed this agreement.<br></br><br></br>
    13.3   The Agreement shall be sufficient for summary judgment, provisional sentence and/or any other legal proceedings and shall be considered to be a liquid document for such purpose.<br></br><br></br>
    13.4    The Client acknowledges that to defend any action instituted by SP Fund based on this Agreement, the Client bears the onus to prove that it is not indebted to SP Fund for the amount as reflected on the certificate.<br></br><br></br>
        <h4 className="ion-text-center"><strong>     BREACH:</strong></h4><br></br>
    14.1    A party shall be deemed to have committed an act of default if such Party:<br></br><br></br>
    14.1.1 Suffers any default judgement in a material amount against it to remain unsatisfied for 14 (FOURTEEN) days, and is refused rescission of such judgement.<br></br><br></br>
    14.1.2 Is placed under business rescue, is wound-up or is liquidated and/or sequestrated (as the case may be), whether compulsorily or voluntarily.<br></br><br></br>
    14.1.3 Compromises with its creditors, or attempts to do so.<br></br><br></br>
    14.1.4 Commits any act of insolvency as defined in the Insolvency Act, 1936 (Act No. 24 of 1936), as amended.<br></br><br></br>
    14.2    Should any Party commit any act in breach of or default on this agreement and fails to remedy such breach within 5 (FIVE) days after receiving written notice to do so from the aggrieved Party shall have the right, without prejudice to any other right which might thereupon be available to it, to enforce the relevant provisions of this agreement, or alternatively to cancel this agreement and/or claim liquidated damaged, if any from the defaulting Party.<br></br>

      </p>,
    },
    {
      title: '',
      content: <p style={{ padding: "20px" }}> <h4 className="ion-text-center"><strong> COSTS:</strong></h4><br></br>
       The costs in connection with and incidental to the drafting and completion of this agreement and all attendances in connection therewith, including disbursements, such legal costs to be calculated on attorney and own client scale, are to be paid by the Client.<br></br><br></br>
        <h4 className="ion-text-center"><strong> ENTIRE AGREEMENT:</strong></h4><br></br>
       This agreement constitutes the entire agreement between parties with regard to the matters dealt with herein and no representations, terms, conditions, or warranties and express or implied not contained in this agreement shall be binding on the Parties.<br></br><br></br>
        <h4 className="ion-text-center"><strong> VARIATION AND CANCELLATION:</strong></h4><br></br>
       No agreement varying, adding to, deleting from or cancelling this agreement, and no waiver whether specifically, implicitly or by conduct of any right to enforce any term of this agreement, shall have effect, unless reduced to writing and signed by on behalf of Parties. It is recorded that there exists no collateral and/or other agreements and that is the sole agreement entered into by and between the Parties.<br></br><br></br>
        <h4 className="ion-text-center"><strong> INDULGENCES:</strong></h4><br></br>
       No indulgences granted by a Party shall constitute a waiver of any of that Party’s rights under this agreement; accordingly, that Party shall not be precluded, as a consequence of having granted such indulgence, from exercising any rights against the other which may have arisen in the past or which may arise in the future.<br></br><br></br>
        <h4 className="ion-text-center"><strong> SEVERABILITY:</strong></h4><br></br>
       Each of the Provisions of this agreement shall be considered as separate terms and conditions and in the event that this agreement is affected by any legislation or any amendment thereto, or if the provisions herein contained are by virtue of that legislation or otherwise held to be illegal, invalid, prohibited or unenforceable, then any such provisions shall be ineffective only to the extent of the illegality, invalidity, prohibition, or unenforceability and each of the remaining provisions hereof shall remain in full force and effect as if the illegal invalid, prohibited or unenforceable provision was not part hereof.<br></br><br></br>
        <h4 className="ion-text-center"><strong> ADDENDUMS:</strong></h4><br></br>
        <TextArea
          value={CurrentProject.CAAddendum}
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.CAAddendum = e.target.value }) }}
          name="CreditAddendum"
          disabled
          placeholder="Provide addendum details for the credit agreement page"
        />
        <br></br>
        <br></br>
        <br></br>
        <h4 className="ion-text-center"><strong> THUS DONE AND SIGNED BY THE RESPECTIVE PARTIES AS FOLLOWS:</strong></h4><br></br>

       SOUTHERN PALACE IMPACT FUND (PTY) LTD<br></br> <br></br>
       Date: <br></br>

       <DatePicker value={moment(CurrentProject.CADate? CurrentProject.CADate:Date.now() )} onChange={(date, dateString) => { UIStore.update(s => { s.CurrentProject.CADate = dateString }) }} /> <br></br><br></br>
       Place:<br></br>
        <Input
          value={CurrentProject.SigningLocation}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.SigningLocation = e.target.value }) }}
          name="SigningLocation"
          placeholder="Location of Signing"
        /><br></br><br></br>
       Witnesses:<br></br>

        <Input
          value={CurrentProject.Witnesses}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.Witnesses = e.target.value }) }}
          name="Witnesses"
          placeholder="Witnesses"
        />
        <br></br><br></br>
        <Input
          value={CurrentProject.Witnesses2}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.Witnesses2 = e.target.value }) }}
          name="Witnesses2"
          placeholder="Witnesses"
        /><br></br><br></br>
        <SignatureCanvas ref={(ref) => { sigCanvas = ref }} penColor='blue'
          canvasProps={{ width: 1300, height: 200, className: 'sigCanvas' }}
          onEnd={() => {

            UIStore.update(s => {
              s.CurrentProject.SignatureCredit = sigCanvas.toDataURL();
            })

          }}

        />




        <p className="ion-text-center" >(Signature required above) </p><br></br><br></br>
        <h4 className="ion-text-center"><strong> WHO WARRANTS THAT HE/SHE IS DULY AUTHORISED</strong></h4><br></br>
       Date: <br></br>
       <DatePicker value={moment(CurrentProject.CADate? CurrentProject.CADate:Date.now() )} onChange={(date, dateString) => { UIStore.update(s => { s.CurrentProject.CADate = dateString }) }} /><br></br><br></br>

       Witnesses:<br></br>
        <Input
          value={CurrentProject.Witnesses3}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.Witnesses3 = e.target.value }) }}
          name="Witnesses3"
          placeholder="Witnesses"
        />
        <br></br><br></br>
        <Input
          value={CurrentProject.Witnesses4}
          type="text"
          onChange={(e: any) => { UIStore.update(s => { s.CurrentProject.Witnesses4 = e.target.value }) }}
          name="Witnesses4"
          placeholder="Witnesses"
        /><br></br><br></br>
      </p>,
    },
  ];



  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            onBack={() => null}
            title="Credit Agreement"

          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCol class="ion-padding">
          <Space direction="vertical">

            <hr></hr>
            <Steps current={current}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current < steps.length - 1 && (
                <IonCol class="ion-padding">
                  <Button size={"large"} type="primary" onClick={(CheckObject) => next()}>
                    Next
        </Button>
                </IonCol>
              )}
              {current === steps.length - 1 && (
                <IonCol class="ion-padding">
                  <Button size={"large"} type="primary" onClick={() => { SubmitCreditAgreement(); }}>
                    Submit
        </Button>
                </IonCol>
              )}
              {current > 0 && (
                <IonCol class="ion-padding">
                  <Button size={"large"} style={{ margin: '0 8px' }} onClick={() => prev()}>
                    Previous
        </Button>
                </IonCol>
              )}
            </div>

          </Space>
        </IonCol>
      </IonContent>
    </IonPage>


  );
};

export default CreditAgreement;