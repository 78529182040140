import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";

import { Descriptions, Row, Col, notification, Tooltip, Modal } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import { Steps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { message } from "antd";
import { DatePicker, Space, Button } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import { UIStore } from "../store/UIStore";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { Content } from "antd/lib/layout/layout";
const dateFormat = "YYYY/MM/DD";
const Layout = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};
const DeclarationPage: React.FC = (props: any) => {
  const { Step } = Steps;
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const text = <span>Submit declaration and Finalize application pack</span>;

  const buttonWidth = 70;

  useIonViewDidEnter(() => {});

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  async function SubmitDeclaration() {
    console.log(CurrentProject);
    message
      .loading("Action in progress..", 5)
      .then(() =>
        message.success(
          "Submission Succeeded, SP Impact Fund will be in touch shortly.",
          5
        )
      );
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdateDec",
          CurrentProject
        )
        .then(async (response) => {
          try {
            const user = JSON.parse(localStorage.getItem("userobject")||'{}');
            const request = await axios
              .post(
                "https://dev.northroom.co.za/api/spfund/users/messageuser/",
                { userid: user.id, message: user.FullName + " Your application pack has been submitted successfully"}
              )
              .then((response2) => {
                message.success(response.data);
                console.log(response);
                props.history.push("/Projects");
              });
          } catch (error) {
            console.error(error);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  const [isModalHelpVisible, setIsModalHelpVisible] = useState(false);

  const showHelpModal = () => {
    setIsModalHelpVisible(true);
  };

  const handleHelpOk = () => {
    SubmitDeclaration();
    props.history.push("/Projects")
    setIsModalHelpVisible(false);
  };

  const handleHelpCancel = () => {
    setIsModalHelpVisible(false);
  };

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              // onBack={() => null}
              title="Declaration"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Space direction="vertical">
          <IonRow style={{ paddingLeft: "7%" }}>
            <IonCol class="ion-padding" size="11">
              <Steps current={2} status="error">
                <Step title="Eligibility Criteria" description="Complete" />
                <Step title="Application Form" description="Complete" />
                <Step
                  title="Compliance Checklist"
                  description="Complete Declaration"
                />
                <Step title="CIC investment" description="Incomplete" />
              </Steps>
            </IonCol>
          </IonRow>
          <br></br>
          <Card style={{ content: "center" }}>
            <Card>
              <h3 style={{ textAlign: "center" }}>
                Declaration by Main Contractor/Sub contractor/Project Owner
              </h3>

              <p style={{ textAlign: "center" }}>
                Please tick the box if you consent to the sharing of financial
                information supplied to the SP impact Fund with an approved and
                authorized financial services provider
              </p>

              <div
                className="ion-text-center"
                style={{ paddingBottom: "50px", paddingTop: "50px" }}
              >
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={CurrentProject.Consent}
                  onChange={(e) => {
                    UIStore.update((s) => {
                      s.CurrentProject.Consent = e;
                    });
                  }}
                />
              </div>

              <h4 style={{ textAlign: "center" }}>
                I, the undersigned, hereby:
              </h4>
              <h4 style={{ textAlign: "center" }}>Declare that:</h4>

              <p style={{ padding: "100px" }}>
                I am duly authorised to sign this application on behalf of the
                enterprise: The Information furnished, as well as all
                documentation submitted in support of this application, is true
                and correct in every respect; and has been lawfully obtained.
                Neither the name of the enterprise nor the name of any partner,
                member, director, manager, or person, who wholly or partly
                exercises, or may exercise, control over the enterprise, appears
                on the Register of Tender Defaulters established in terms of the
                Prevention and Combating of Corrupt Activities Act of 2004. No
                partner, member, director, manager, or other person, who wholly
                or partly exercises, or may exercise, control over the
                enterprise, has within the last five years been convicted of
                fraud or corruption·: I will provide to SP Impact Fund any
                additional information that may be required with regards to this
                application. Understand that: Participation is subject to the
                conditions referred to on Schedule 1 of the Funding Agreement
                Incomplete applications result in delays in processing.
                Applications without relevant supporting documentation will not
                be processed. SP Impact Fund will evaluate my enterprise based
                on the information provided.
              </p>

              {/* <p style={{ textAlign: "center" }}>Date Signed</p> */}
              <div className="ion-text-center">
                <DatePicker
                  className="ion-text-center"
                  value={moment(CurrentProject.DecDate)}
                  onChange={(date, dateString) => {
                    UIStore.update((s) => {
                      s.CurrentProject.DecDate = dateString;
                    });
                  }}
                />
              </div>
              <br></br>
              <div style={{ paddingLeft: "300px", paddingRight: "300px" }}>
                <Input
                  style={{ textAlign: "center" }}
                  value={CurrentProject.RepNameTitle}
                  placeholder="Representative Name and Title"
                  type="text"
                  onChange={(e: any) => {
                    UIStore.update((s) => {
                      s.CurrentProject.RepNameTitle = e.target.value;
                    });
                  }}
                  name="NameTitle"
                />
              </div>
              <br></br>
              <Card style={{ padding: "10px" }}>
                <p className="ion-text-center">Authorized Signature</p>

                <SignatureCanvas
                  ref={(ref) => {
                    sigCanvas = ref;
                  }}
                  penColor="blue"
                  canvasProps={{
                    width: 1300,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={() => {
                    let signature = sigCanvas.toDataURL();
                    UIStore.update((s) => {
                      s.CurrentProject.SignatureDeclaration = signature;
                    });
                  }}
                />
              </Card>
              <br></br>
              <Tooltip placement="top" title={text}>
                <div
                  className="ion-text-center"
                  style={{ paddingBottom: "10px" }}
                >
                  <Button
                    onClick={() => {
                      showHelpModal();
                    }}
                    type="primary"
                  >
                    Submit
                  </Button>
                  <Modal title="" visible={isModalHelpVisible} onOk={handleHelpOk} onCancel={handleHelpCancel}>
                      <p><strong>You have completed the Application stage!</strong></p>
                      <p>Your submission has been sent to an SP Fund Application officer for verification.</p>
                      <p>You will receive email notification regarding the status of your application.</p>
                     
                    </Modal>
                </div>
              </Tooltip>
            </Card>
          </Card>
        </Space>
      </IonContent>
    </IonPage>
  );
};

export default DeclarationPage;
