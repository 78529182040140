import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Modal,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";

import { message, Tag } from "antd";

import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";

import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
const dateFormat = "YYYY/MM/DD";

const ApplicationsOfficer: React.FC = (props: any) => {
  const [applicationpacks, setapplicationpacks] = useState<any[]>([]);
  const { Step } = Steps;

  const [data, setdata] = useState<any[]>([]);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  // async function SubmitApplicationPack() {

  //   message
  //     .loading('Action in progress..', 5)
  //     .then(() => message.success('Submission Succeeded', 5));
  //   try {
  //     const request = await axios.post('https://dev.northroom.co.za/api/spfund/users/submitapplicationScreen', ApplicationObject).then((response) => {

  //       // message.success(response.data);
  //       // console.log(response);
  //       props.history.push("/CreditAgreement");

  //     });

  //   } catch (error) {
  //     message.error(error);
  //   }

  // }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function ApproveProject(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/markverifiedappofficer",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/appofficer"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error) {
      message.error(error);
    }
    try {
            const user = JSON.parse(localStorage.getItem("userobject")||'{}');
            const request = await axios
              .post(
                "https://dev.northroom.co.za/api/spfund/users/messageuser/",
                { userid: 43, message: "Application Officer has submitted an application pack for you to review."}
              )
              .then((response2) => {
                
              });
          } catch (error) {
            console.error(error);
          }
  }
  async function ExceptionReport(object: any) {
    object.AppOfficerExceptioncomment = exception;
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as Exception", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/markverifiedappofficerexception",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/appofficer"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error) {
      message.error(error);
    }
  }

  useIonViewWillEnter(() => {
    axios
      .get(
        "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/appofficer"
      )
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  //modal
  const [ exception,setException] =  useState("")
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setException("");
    setVisible(false);
  };

  const handleComment = (object: any) => {
    console.log(object);
   ExceptionReport(object);
    setVisible(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="Applications Verification"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow style={{ paddingLeft: "6%" }}>
          <IonCol class="ion-padding" size="11">
            <Steps current={0} status="error">
              <Step
                title="Application Pack Verification"
                description="In Progress"
              />
              <Step
                title="Application Pack Verification"
                description="Compliance verification incomplete"
              />
              <Step
                title="Application Pack Verification"
                description="Application ERC review incomplete"
              />
              <Step
                title="Application Pack Submission to TEC"
                description="Pending TEC vote"
              />
            </Steps>
          </IonCol>
        </IonRow>

        <>
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={
                        <p>Project Application ID:{dataobject.ProjectName}</p>
                      }
                      description="Ready for Application Verification"
                    />
                  </List.Item>

                  <List.Item>
                    <IonRow>
                      <IonCol>
                        <Button
                          onClick={() => {
                            ApproveProject(dataobject);
                          }}
                          type="primary"
                        >
                          Mark as Verified
                        </Button>
                      </IonCol>

                      <IonCol>
                        <Button
                          onClick={() => {
                            showModal();
                          }}
                          type="primary"
                        >
                          Mark for Exception Report
                        </Button>
                      </IonCol>

                      <IonCol></IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <Tag>
                          <a href={dataobject.EligibilityCriteriaForm} download>
                            Eligibility Criteria Download
                          </a>
                        </Tag>
                        <Tag>
                          <a href={dataobject.ApplicationForm} download>
                            Application Form Download
                          </a>
                        </Tag>
                        <Tag>
                          <a href={dataobject.DeclarationForm} download>
                            Declaration Form Download
                          </a>
                        </Tag>
                        <Tag>
                          <a href={dataobject.CIPCForm} download>
                            CPIC Report Download
                          </a>
                        </Tag>
                      </IonCol>
                    </IonRow>
                    <Modal
                      title="Exception"
                      visible={visible}
                      onCancel={handleCancel}
                      width={500}
                      centered
                      onOk={() => {
                        handleComment(dataobject);
                      }}
                    >
                      <IonRow>
                        <IonCol>
                          <TextArea
                            value={exception}
                            rows={4}
                            onChange={(e) => {setException(e.target.value)}}
                            name="exception"
                            placeholder="exception comment"
                          />
                        </IonCol>
                      </IonRow>
                    </Modal>
                  </List.Item>
                </>
              ))
            }
          />
        </>
      </IonContent>
    </IonPage>
  );
};

export default ApplicationsOfficer;
