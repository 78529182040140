import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import { useParams } from "react-router";

import {
  Descriptions,
  Row,
  Col,
  notification,
  List,
  Avatar,
  Button,
  Steps,
  Modal,
} from "antd";
import { Card } from "antd";
import { Switch, Radio } from "antd";
import { Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { message, Tag } from "antd";
import { DatePicker, Space } from "antd";
import "./Page2.css";
import { PageHeader } from "antd";
import React, { useState } from "react";
import logo from "../pages/images/image6.jpg";

import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import { DownloadOutlined } from "@ant-design/icons";
const dateFormat = "YYYY/MM/DD";

const TDRiskOfficer: React.FC = (props: any) => {
  const [applicationpacks, setapplicationpacks] = useState<any[]>([]);
  const { Step } = Steps;

  const [data, setdata] = useState<any[]>([]);

  const { TextArea } = Input;
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};
  function onChange(unchecked: any) {
    console.log(`switch to ${unchecked}`);
  }

  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  async function ApproveProject(object: any) {
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/markverifiedERC",
          object
        )
        .then(async (response) => {
          try {
            const user = JSON.parse(localStorage.getItem("userobject")||'{}');
            const request = await axios
              .post(
                "https://dev.northroom.co.za/api/spfund/users/messageuser/",
                { userid: user.id, message: user.FullName + " Your application pack has been fully verified, you will now be assigned a project manager."}
              )
              .then((response2) => {
                message.success(response.data);
                console.log(response);
                props.history.push("/Projects");
              });
          } catch (error) {
            console.error(error);
          }
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/ERC"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error) {
      message.error(error.data);
    }
  }

  async function ExceptionReport(object: any) {
    object.RiskOfficerExceptioncomment = exception;
    message
      .loading("Action in progress..", 3)
      .then(() => message.success("Marked as verified", 2));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/markverifiedERCexception",
          object
        )
        .then((response) => {
          axios
            .get(
              "https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/ERC"
            )
            .then(function (response) {
              console.log(response.data);

              setdata(response.data);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            });
        });
    } catch (error) {
      message.error(error);
    }
  }
  async function DownloadPack(object: any) {}

  useIonViewWillEnter(() => {
    axios
      .get("https://dev.northroom.co.za/api/spfund/legacy/users/projectlistall/ERC")
      .then(function (response) {
        console.log(response.data);

        setdata(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  });

  //modal
  const [ exception,setException] =  useState("")
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setException("");
    setVisible(false);
  };

  const handleComment = (object: any) => {
    console.log(object);
     ExceptionReport(object);
    setVisible(false);
  };



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonRow>
            <PageHeader
              className="site-page-header"
              title="Technical Director/Risk Officer"
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRow style={{ paddingLeft: "6%" }}>
          <IonCol class="ion-padding" size="11">
            <Steps current={2} status="error">
              <Step title="Eligibility Criteria" description="Complete" />
              <Step title="Application Form" description="Complete" />
              <Step
                title="Compliance Checklist"
                description="Awaiting Technical Director/Risk Officer verification"
              />
              <Step title="CIC investment" description="Incomplete" />
            </Steps>
          </IonCol>
        </IonRow>

        <>
          <List
            dataSource={[
              {
                name: "Application 1",
              },
            ]}
            bordered
            renderItem={(item) =>
              data.map((dataobject) => (
                <>
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={logo} />}
                      title={<p>t Application ID:{dataobject.id}</p>}
                      description="Pending ERC Outcome"
                    />

                    {/* <p>Registration with CIBD</p>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} onChange={e => { handleChangecheckbox(e, "CIBD") }} />
                <br></br>
                <br></br>
                {commentbox1 ? <TextArea placeholder="Provide a reason/Ask for help?" rows={4} /> : null} */}

                    {/* <Tag>
    <a href="https://dev.northroom.co.za/api/spfund/static/b9c1227be29a3ff58094364939823ae456dcd1978008df99.png" download>CPIC Page 1 Report</a>
    </Tag>
    <Tag>
    <a href="https://dev.northroom.co.za/api/spfund/static/7ed4fb7ab52101ac1bf8df1f7470c97ff14886f784423227.png" download>CPIC Page 2 Report</a>
    </Tag> 
    <Tag>
    <a href="https://dev.northroom.co.za/api/spfund/static/8b654c2c8191611bb54f2e543dfd6952b3c2f0df913b6f13.png" download>CPIC Page 3 Report</a>
    </Tag>  */}
                    {/* <Tag>
    <a href={dataobject.Credit} download>Credit Agreement</a>
    </Tag>  */}
                  </List.Item>

                  <List.Item>
                    {/* 
                    <IonRow>
                      <Tag>
                        <a href={dataobject.Spif} download>Eligibility Criteria</a>
                      </Tag>
                      <Tag>
                        <a href={dataobject.BOQd} download >CIDB certificate </a>
                      </Tag>
                      <Tag>
                        <a href={dataobject.BOQd} download >Application Form  </a>
                      </Tag>
                    </IonRow> */}

                    <IonRow>
                      <IonCol>
                        <Button
                          onClick={() => {
                            ApproveProject(dataobject);
                          }}
                          type="primary"
                        >
                          Mark as Verified
                        </Button>
                      </IonCol>

                      <IonCol>
                        <Button
                          onClick={() => {
                            showModal();
                          }}
                          type="primary"
                        >
                          Create Exception Report
                        </Button>
                      </IonCol>

                      <IonCol>
                        <Button
                          onClick={() => {
                            DownloadPack(dataobject);
                          }}
                          type="primary"
                          disabled
                          icon={<DownloadOutlined />}
                        >
                          Download
                        </Button>
                      </IonCol>
                    </IonRow>
                  </List.Item>

                  <IonRow>
                    <IonCol>
                      <Tag>
                        <a href={dataobject.EligibilityCriteriaForm} download>
                          Eligibility Criteria Download
                        </a>
                      </Tag>
                      <Tag>
                        <a href={dataobject.ApplicationForm} download>
                          Application Form Download
                        </a>
                      </Tag>
                      <Tag>
                        <a href={dataobject.DeclarationForm} download>
                          Declaration Form Download
                        </a>
                      </Tag>
                      <Tag>
                        <a href={dataobject.CIPCForm} download>
                          CPIC Report Download
                        </a>
                      </Tag>
                    </IonCol>
                  </IonRow>
                  <Modal
                      title="Exception"
                      visible={visible}
                      onCancel={handleCancel}
                      width={500}
                      centered
                      onOk={() => {
                        handleComment(dataobject);
                      }}
                    >
                      <IonRow>
                        <IonCol>
                          <TextArea
                            value={exception}
                            rows={4}
                            onChange={(e) => {setException(e.target.value)}}
                            name="exception"
                            placeholder="exception comment"
                          />
                        </IonCol>
                      </IonRow>
                    </Modal>
                </>
              ))
            }
          />
        </>
      </IonContent>
    </IonPage>
  );
};

export default TDRiskOfficer;
