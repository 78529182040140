import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonItemDivider,
    IonLabel,
    useIonViewDidEnter,
    useIonViewDidLeave
} from '@ionic/react';
import { useParams } from 'react-router';

import { Descriptions, Row, Col, notification, List, Avatar, } from 'antd';
import { Card } from 'antd';
import { Switch, Radio } from 'antd';
import { Input } from 'antd';
import { DownloadOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { message, Tag, Steps } from 'antd';
import { DatePicker, Space, Button } from 'antd';
import './Page2.css';
import { PageHeader } from 'antd';
import React, { useState } from 'react';
import logo from '../pages/images/image6.jpg';

import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas';
const dateFormat = 'YYYY/MM/DD';

const CicStatus: React.FC = (props: any) => {
    const [commentbox1, setShowcommentbox1] = useState(true);
    const [commentbox2, setShowcommentbox2] = useState(true);
    const [commentbox3, setShowcommentbox3] = useState(true);
    const [commentbox4, setShowcommentbox4] = useState(true);
    const [commentbox5, setShowcommentbox5] = useState(true);
    const [commentbox6, setShowcommentbox6] = useState(true);
    const [commentbox7, setShowcommentbox7] = useState(true);
    const [commentbox8, setShowcommentbox8] = useState(true);
    const [commentbox9, setShowcommentbox9] = useState(true);
    const [commentbox10, setShowcommentbox10] = useState(true);
    const [commentbox11, setShowcommentbox11] = useState(true);
    const [vote, setvote] = useState("Unvoted");
    const [data, setdata] = useState<any[]>([])
    const { Step } = Steps;
    const { TextArea } = Input;
    const { name } = useParams<{ name: string; }>();
    var sigCanvas: any = ({});
    function onChange(unchecked: any) {
        console.log(`switch to ${unchecked}`);
    }



    function CheckObject() {
        const isEmpty = !Object.values(ApplicationObject).some(x => (x !== null && x !== ''));
        console.log(ApplicationObject);
        if (isEmpty === false) {
            notification['error']({
                message: 'Error',
                description:
                    'All fields must be completed',
            });
        }
    }

    async function SubmitApplication() {

        message
            .loading('Action in progress..', 5)
            .then(() => message.success('Submission Succeeded', 5));
        try {
            const request = await axios.post('https://dev.northroom.co.za/api/spfund/legacy/users/submitapplicationScreen', ApplicationObject).then((response) => {

                // message.success(response.data);
                // console.log(response);
                props.history.push("/CreditAgreement");


            });

        } catch (error) {
            message.error(error);
        }

    }

    function DownloadPack() {

    }


    const user = JSON.parse(localStorage.getItem("userobject") || '{}');
    const [ApplicationObject, setState] = useState({
        userid: "",
        SignatureApplication: "",
        Location: "",
        Date: "",
        Signatory: "",
        Capacity: "",
        CIBD: "",
        Ownership: "",
        ApplicationParticipation: "",
        MinimumWage: "",
        RingFenced: "",
        DevelopmentPlan: "",
        Training: "",
        Experience: "",
        DevelopmentAgreement: "",
        Assesment: "",
        MaterialsAgreement: "",
        VotingComment: "",
    });



    useIonViewDidEnter(() => {
        axios.get('https://dev.northroom.co.za/api/spfund/legacy/users/compliance')
            .then(function (response) {

                setdata(response.data);



            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    });

    useIonViewDidLeave(() => {
        console.log('ionViewDidLeave event fired');
    });

    async function CreateTECVote() {

        message
            .loading('Action in progress..', 5)
            .then(() => message.success('Submission Succeeded', 5));
        try {
            const request = await axios.post('https://dev.northroom.co.za/api/spfund/users/submitjvscreen', ApplicationObject).then((response) => {

                // message.success(response.data);
                // console.log(response);
                props.history.push("/TECVoting");


            });

        } catch (error) {
            message.error(error);
        }

    }

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));

        setState(prevState => ({
            ...prevState,
            userid: user.id
        }));
    };
    const handleChangecheckbox = (e: any, name: any) => {

        setState(prevState => ({
            ...prevState,
            [name]: e
        }));

        if (name === "CIBD") {

            setShowcommentbox1(!commentbox1);
        }

        if (name === "Ownership") {

            setShowcommentbox2(!commentbox2);
        }

        if (name === "ApplicationParticipation") {

            setShowcommentbox3(!commentbox3);
        }

        if (name === "MinimumWage") {

            setShowcommentbox4(!commentbox4);
        }

        if (name === "RingFenced") {

            setShowcommentbox5(!commentbox5);
        }

        if (name === "DevelopmentPlan") {

            setShowcommentbox6(!commentbox6);
        }

        if (name === "Training") {

            setShowcommentbox7(!commentbox7);
        }

        if (name === "Experience") {

            setShowcommentbox8(!commentbox8);
        }

        if (name === "DevelopmentAgreement") {

            setShowcommentbox9(!commentbox9);
        }

        if (name === "Assesment") {

            setShowcommentbox10(!commentbox10);
        }

        if (name === "MaterialsAgreement") {

            setShowcommentbox11(!commentbox11);
        }
    };


    const handleSizeChange = (e: any) => {

        setvote(e.target.value);
    };

    function onChangeDatePicker(DateWorkCompleted: any, dateString: any) {
        setState(prevState => ({
            ...prevState,
            Date: dateString
        }));
    }

    const handleChangeSignature = (e: any, name: any) => {

        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const [Manager, setManager] = useState(null)
    const handleClick = () => setManager(Manager)

    function submitHandler(e: { target: { value: React.SetStateAction<null>; }; }) {
        setManager(e.target.value)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonRow>
                        <PageHeader
                            className="site-page-header"

                            title="CIC Status"

                        />

                    </IonRow>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen >

                <>

                    <List
                        dataSource={[
                            {
                                name: 'Application 1',

                            },

                        ]}
                        bordered
                        renderItem={item => (


                            data.map((dataobject) =>
                                <>

                                    <List.Item

                                    >
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar src={logo} />
                                            }
                                            title={<p >Project Application ID:{dataobject.ProjectName}</p>}
                                            description="In Progress Application"
                                        />


                                        {/* <p>Registration with CIBD</p>
                  <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} onChange={e => { handleChangecheckbox(e, "CIBD") }} />
                  <br></br>
                  <br></br>
                  {commentbox1 ? <TextArea placeholder="Provide a reason/Ask for help?" rows={4} /> : null} */}


                                        <Row style={{ paddingLeft: "50px" }}>
                                            <Col style={{ paddingRight: "50px" }}>

                                                <Row>
                                                    <TextArea value={ApplicationObject.VotingComment} placeholder="Record of Decision" rows={4} cols={36} />

                                                </Row>
                                            </Col>

                                        </Row>




                                    </List.Item>



                                </>
                            )
                        )}
                    />



                </>

            </IonContent>
        </IonPage>


    );
};

export default CicStatus;
