import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonRow,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { useParams } from "react-router";
import { Typography } from "antd";
import { Row, TreeSelect, Table, InputNumber } from "antd";
import { Card } from "antd";
import { Switch } from "antd";
import { Input } from "antd";
import { DatePicker, Space, Button, Tooltip } from "antd";
import "./Page2.css";
import moment from "moment";
import { Steps, message } from "antd";
import React, { useState } from "react";
import { PageHeader } from "antd";
import SignatureCanvas from "react-signature-canvas";
import ReactDOM from "react-dom";
import axios from "axios";
import { setConstantValue } from "typescript";
import { TreeNode } from "antd/lib/tree-select";
import Modal from "antd/lib/modal/Modal";
import { PlusOutlined, ProjectOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { UIStore } from "../store/UIStore";
const dateFormat = "YYYY/MM/DD";
const { Title } = Typography;

const columns = [
  {
    title: "Year",
    dataIndex: "Year",
  },
  {
    title: "Description of project",
    dataIndex: "Description",
  },
  {
    title: "Value of project",
    dataIndex: "Value",
  },
  {
    title: "Client",
    dataIndex: "Client",
  },
];

const ApplicationForm: React.FC = (props: any) => {
  const { name } = useParams<{ name: string }>();
  var sigCanvas: any = {};

  // function onChange(checked: any) {
  //   console.log(`switch to ${checked}`);
  // }

  const [isModalHelpVisible, setIsModalHelpVisible] = useState(false);

  const showHelpModal = () => {
    setIsModalHelpVisible(true);
  };

  const handleHelpOk = () => {
    SubmitApplication();
    props.history.push("/ComplianceChecklist");
    setIsModalHelpVisible(false);
  };

  const handleHelpCancel = () => {
    setIsModalHelpVisible(false);
  };

  const [signatureResult, setSignatureResult] = useState("");
  const CurrentProject = UIStore.useState((s) => s.CurrentProject);

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalTextProjectName, setModalTextProjectName] = React.useState("");
  const [modalTextYear, setModalTextYear] = React.useState("");
  const [modalTextDescription, setModalTextDescription] = React.useState("");
  const [modalTextValue, setModalTextValue] = React.useState("");
  const [modalTextClient, setModalTextClient] = React.useState("");
  const [value, setValue] = useState(undefined);
  const user = JSON.parse(localStorage.getItem("userobject") || "{}");

  const [Projects, SetProjects] = React.useState<any>([
    { ProjectName: "", Year: "", Description: "", Value: "", Client: "" },
  ]);
  const section = <span>Next section</span>;
  const submit = <span>Submit and proceed to compliance checklist.</span>;
  const back = <span>Previous section</span>;
  const create = <span>Fill in project history </span>;
  const buttonWidth = 70;

  useIonViewDidEnter(() => {
    
    // if (CurrentProject.BusinessProjects.length > 3) {
    //   SetProjects(JSON.parse(CurrentProject.BusinessProjects))
    // }
   
  });

  async function SubmitApplication() {
    console.log(CurrentProject);
    message
      .loading("Action in progress..", 5)
      .then(() => message.success("Submission Succeeded", 5));
    try {
      const request = await axios
        .post(
          "https://dev.northroom.co.za/api/spfund/legacy/users/ProjectUpdate/Application",
          CurrentProject
        )
        .then((response) => {
          // message.success(response.data);
          console.log(response);
          props.history.push("/ComplienceChecklist");
        });
    } catch (error) {
      console.error(error);
    }
  }

  useIonViewDidLeave(() => {
    console.log("ionViewDidLeave event fired");
  });

  // const [modalText2, setModalText2] = React.useState('Some other input');

  const showModal = () => {
    setVisible(true);
  };

  const CreateProject = async () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);

    SetProjects((oldArray: any) => [
      ...oldArray,
      {
        ProjectName: modalTextProjectName,
        Year: modalTextYear,
        Description: modalTextDescription,
        Value: modalTextValue,
        Client: modalTextClient,
      },
    ]);
    setModalTextDescription("");
    setModalTextValue("");
    setModalTextYear("");
    setModalTextClient("");
    setVisible(false);

    //UIStore.update(s => { s.CurrentProject.BusinessProjects = JSON.stringify(Projects) })
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const { Step } = Steps;

  const steps = [
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Business Name</strong>
              </p>
              <Input
                value={CurrentProject.BusinessName}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessName = e.target.value;
                  });
                }}
                name="BusinessName"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Name of Main Contract/Sub Contractor/Project Owner
                </strong>
              </p>
              <Input
                value={CurrentProject.BusinessOwners}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessOwners = e.target.value;
                  });
                }}
                name="BusinessOwners"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>CIPC Registration</strong>
              </p>
              <Input
                value={CurrentProject.BusinessRegistration}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessRegistration = e.target.value;
                  });
                }}
                name="BusinessRegistration"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Business Registration Date</strong>
              </p>

              <DatePicker
                value={moment(CurrentProject.BusinessRegistrationDate)}
                onChange={(date, dateString) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessRegistrationDate = dateString;
                  });
                }}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Trading Start Date</strong>
              </p>

              <DatePicker
                value={moment(CurrentProject.TradingStartDate)}
                onChange={(date, dateString) => {
                  UIStore.update((s) => {
                    s.CurrentProject.TradingStartDate = dateString;
                  });
                }}
              />
            </IonCol>
          </Row>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Legal Entity (For e.g. , Pty Ltd, CC, Partnership etc.)
                </strong>
              </p>
              <Input
                value={CurrentProject.LegalEntity}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.LegalEntity = e.target.value;
                  });
                }}
                name="LegalEntity"
                placeholder=""
              />
            </IonCol>
          </Row>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Tax Clearance Certificate Number</strong>
              </p>
              <Input
                value={CurrentProject.TaxClearance}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.TaxClearance = e.target.value;
                  });
                }}
                name="TaxClearance"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Physical Address</strong>
              </p>
              <Input
                value={CurrentProject.PhysicalAddress}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.PhysicalAddress = e.target.value;
                  });
                }}
                name="PhysicalAddress"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Postal Address</strong>
              </p>
              <Input
                value={CurrentProject.PostalAddress}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.PostalAddress = e.target.value;
                  });
                }}
                name="PostalAddress"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>GPS Coordinates (where available)</strong>
              </p>
              <Input
                value={CurrentProject.GPSCoordinates}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.GPSCoordinates = e.target.value;
                  });
                }}
                name="GPSCoordinates"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row style={{ paddingLeft: "10px" }}>
            <label>
              <strong>Business Size</strong>
            </label>
            <br></br>
            <TreeSelect
              showSearch
              style={{ width: "100%" }}
              value={CurrentProject.BusinessSize}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Select Business Size"
              allowClear
              treeDefaultExpandAll
              onChange={(e: any) => {
                UIStore.update((s) => {
                  s.CurrentProject.BusinessSize = e;
                });
              }}
            >
              <TreeNode value="Micro" title="Micro">
                <TreeNode value="0-10 Employees" title="0-10 Employees" />
                <TreeNode value="parent 1-0" title="Small">
                  <TreeNode value="11-50 Employees" title="11-50 Employees" />
                </TreeNode>
                <TreeNode value="parent 1-1" title="Medium">
                  <TreeNode
                    value="51-250 Employees"
                    title={<b style={{ color: "#08c" }}>51-250 Employees</b>}
                  />
                </TreeNode>
              </TreeNode>
            </TreeSelect>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Black Ownership</strong>
              </p>

              <Input
                value={CurrentProject.BlackOwnership}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BlackOwnership = e.target.value;
                  });
                }}
                name="BlackOwnership"
                suffix="%"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong> Woman Ownership</strong>
              </p>
              <Input
                value={CurrentProject.WomanOwnership}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.WomanOwnership = e.target.value;
                  });
                }}
                name="WomanOwnership"
                suffix="%"
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong> Youth Ownership</strong>
              </p>
              <Input
                value={CurrentProject.YouthOwnership}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.YouthOwnership = e.target.value;
                  });
                }}
                name="YouthOwnership"
                suffix="%"
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Sector in which Entity Operates</strong>
              </p>
              <Input
                value={CurrentProject.BusinessSector}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessSector = e.target.value;
                  });
                }}
                name="BusinessSector"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Services</strong>
              </p>
              <Input
                value={CurrentProject.Services}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Services = e.target.value;
                  });
                }}
                name="Services"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>CIDB Grade</strong>
              </p>
              <Input
                value={CurrentProject.CIDBGrade}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.CIDBGrade = e.target.value;
                  });
                }}
                name="CIDBGrade"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Annual Revenue for the Most Recent Finacial period
                </strong>
              </p>
              <Input
                value={CurrentProject.AnnualRevenue1}
                prefix="R"
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.AnnualRevenue1 = e.target.value;
                  });
                }}
                name="AnnualRevenue"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Method of Funding the Business (e.g. personal savings,
                  friends, family,commercial)
                </strong>
              </p>
              <Input
                value={CurrentProject.FundingMethod1}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.FundingMethod1 = e.target.value;
                  });
                }}
                name="FundingMethod1"
                placeholder=""
              />
            </IonCol>
          </Row>
        </p>
      ),
    },
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          <h4>SECTION 2 ABOUT YOUR ORGANISATIONS CONTACT PEOPLE</h4>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Title</strong>
              </p>
              <Input
                value={CurrentProject.Title}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Title = e.target.value;
                  });
                }}
                name="Title"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Initials</strong>
              </p>
              <Input
                value={CurrentProject.Initials}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Initials = e.target.value;
                  });
                }}
                name="Initials"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Surname</strong>
              </p>
              <Input
                value={CurrentProject.BusinessOwnerSurname}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.BusinessOwnerSurname = e.target.value;
                  });
                }}
                name="BusinessOwnerSurname"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>ID or Passport number</strong>
              </p>
              <Input
                value={CurrentProject.IDPassport}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.IDPassport = e.target.value;
                  });
                }}
                name="IDPassport"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Country of issue</strong>
              </p>
              <Input
                value={CurrentProject.CountryOfIssue}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.CountryOfIssue = e.target.value;
                  });
                }}
                name="CountryOfIssue"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Race</strong>
              </p>
              <Input
                value={CurrentProject.Race}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Race = e.target.value;
                  });
                }}
                name="Race"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Designation</strong>
              </p>
              <Input
                value={CurrentProject.Designation}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Designation = e.target.value;
                  });
                }}
                name="Designation"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Telephone</strong>
              </p>
              <Input
                value={CurrentProject.Telephone}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Telephone = e.target.value;
                  });
                }}
                name="Telephone"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Cellphone</strong>
              </p>
              <Input
                value={CurrentProject.Cellphone}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Cellphone = e.target.value;
                  });
                }}
                name="Cellphone"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Email address</strong>
              </p>
              <Input
                value={CurrentProject.Email}
                type="email"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Email = e.target.value;
                  });
                }}
                name="Email"
                placeholder=""
              />
            </IonCol>
          </Row>

          <div style={{ paddingLeft: "20px" }}></div>
        </p>
      ),
    },
    {
      title: "",
      content: (
        <p style={{ padding: "20px" }}>
          <h4 style={{ paddingLeft: "20px" }}>
            SECTION 3 MAIN CONTRACTORS/SUB CONTRACTOR/ PROJECT OWNER HISTORY
            <IonRow>
              <IonCol>
                <Card>
                  <Tooltip placement="top" title={create}>
                    <Button
                      type="primary"
                      onClick={showModal}
                      icon={<PlusOutlined />}
                    >
                      Add project
                    </Button>
                  </Tooltip>
                  <Modal
                    title="New Project"
                    visible={visible}
                    onOk={CreateProject}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                  >
                    <p>Project Year</p>
                    <Input
                      style={{ padding: "10px" }}
                      value={modalTextYear}
                      size="large"
                      placeholder=""
                      onChange={(e) => {
                        setModalTextYear(e.target.value);
                      }}
                      prefix={<ProjectOutlined />}
                    />
                    <br></br>
                    <p></p>
                    <p>Project Description</p>
                    <Input
                      style={{ padding: "10px" }}
                      value={modalTextDescription}
                      size="large"
                      onChange={(e) => {
                        setModalTextDescription(e.target.value);
                      }}
                      prefix={<ProjectOutlined />}
                    />
                    <br></br>
                    <p></p>
                    <p>Project Value</p>
                    <Input
                      style={{ padding: "10px" }}
                      value={modalTextValue}
                      size="large"
                      placeholder=""
                      onChange={(e) => {
                        setModalTextValue(e.target.value);
                      }}
                      prefix={<ProjectOutlined />}
                    />
                    <br></br>
                    <p></p>
                    <p>Client</p>
                    <Input
                      style={{ padding: "10px" }}
                      value={modalTextClient}
                      size="large"
                      placeholder=""
                      onChange={(e) => {
                        setModalTextClient(e.target.value);
                      }}
                      prefix={<ProjectOutlined />}
                    />
                    <br></br>
                    <p></p>

                    <br></br>
                    <br></br>
                    {/* <p>{modalText2}</p>

                <Input 
                size="large" 
                placeholder="" 
                prefix={<UserOutlined />} /> */}
                  </Modal>
                </Card>
              </IonCol>
            </IonRow>
          </h4>

          <div style={{ paddingLeft: "20px" }}>
            <Table columns={columns} dataSource={Projects} size="middle" />
          </div>

          <h4 style={{ paddingLeft: "20px" }}>SECTION 4 PROJECT INFORMATION</h4>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>
                  Projection Description as per Appointment Letter
                </strong>
              </p>
              <Input
                value={CurrentProject.Projection}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Projection = e.target.value;
                  });
                }}
                name="Projection"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Value of Project as per Appointment Letter</strong>
              </p>
              <Input
                value={CurrentProject.ValueOfProject}
                type="number"
                prefix="R"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ValueOfProject = e.target.value;
                  });
                }}
                name="ValueOfProject"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Client</strong>
              </p>
              <Input
                value={CurrentProject.Client}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Client = e.target.value;
                  });
                }}
                name="Client"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Clients Contact Number</strong>
              </p>
              <Input
                value={CurrentProject.ClientContact}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ClientContact = e.target.value;
                  });
                }}
                name="ClientContact"
                placeholder=""
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Duration of Project</strong>
              </p>
              <Input
                value={CurrentProject.ProjectDuration}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectDuration = e.target.value;
                  });
                }}
                name="ProjectDuration"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Start Date of the Project</strong>
              </p>

              <DatePicker
                value={moment(CurrentProject.ProjectStart)}
                onChange={(date, dateString) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectStart = dateString;
                  });
                }}
              />
            </IonCol>
          </Row>
          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Project Completion Date</strong>
              </p>

              <DatePicker
                value={moment(CurrentProject.ProjectEnd)}
                onChange={(date, dateString) => {
                  UIStore.update((s) => {
                    s.CurrentProject.ProjectEnd = dateString;
                  });
                }}
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Location of Project</strong>
              </p>
              <Input
                value={CurrentProject.Location}
                type="text"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.Location = e.target.value;
                  });
                }}
                name="Location"
                placeholder=""
              />
            </IonCol>
          </Row>

          <Title
            style={{ marginTop: "50px" }}
            className="ion-text-center"
            level={5}
          >
            Number of jobs to be created from project
          </Title>

          <Row>
            <IonCol class="ion-padding">
              <p>
                <strong>Permanent</strong>
              </p>
              <Input
                value={CurrentProject.PermanentJobs}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.PermanentJobs = e.target.value;
                  });
                }}
                name="PermanentJobs"
                placeholder=""
              />
            </IonCol>
            <IonCol class="ion-padding">
              <p>
                <strong>Temporary</strong>
              </p>
              <Input
                value={CurrentProject.TemporaryJobs}
                type="number"
                onChange={(e: any) => {
                  UIStore.update((s) => {
                    s.CurrentProject.TemporaryJobs = e.target.value;
                  });
                }}
                name="TemporaryJobs"
                placeholder=""
              />
            </IonCol>
          </Row>
        </p>
      ),
    },
  ];

  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <PageHeader
            className="site-page-header"
            onBack={() => null}
            title="Application Form"
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCol class="ion-padding">
          <hr></hr>
          <IonRow>
            <IonCol class="ion-padding">
              <Steps style={{ padding: "20px" }} current={current}>
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </IonCol>
          </IonRow>
          <div className="steps-content">{steps[current].content}</div>
          <div className="steps-action">
            {current < steps.length - 1 && (
              <IonCol class="ion-padding">
                <Tooltip placement="top" title={section}>
                  <Button
                    size={"large"}
                    type="primary"
                    onClick={(CheckObject) => next()}
                  >
                    Next
                  </Button>
                </Tooltip>
              </IonCol>
            )}
            {current === steps.length - 1 && (
              <IonCol class="ion-padding">
                {/* <Link to="/ComplienceChecklist"> */}
                <Tooltip placement="top" title={submit}>
                  <Button
                    size={"large"}
                    type="primary"
                    onClick={() => {
                      showHelpModal();
                    }}
                  >
                    Submit Form
                  </Button>
                  <Modal
                    title=""
                    visible={isModalHelpVisible}
                    onOk={handleHelpOk}
                    onCancel={handleHelpCancel}
                  >
                    <p>
                      <strong>Proceeding to the Compliance checklist</strong>
                    </p>
                    <p>
                      <strong>
                        You will be required to upload the following documents:
                      </strong>
                    </p>
                    <p>Company profile</p>
                    <p>Company registration documents</p>
                    <p>B-BBEE certificate</p>
                    <p>Marital status and contract</p>
                    <p>ID of all owners or directors at group level</p>
                    <p>Proof of address</p>
                    <p>Company and project responsible personnel</p>
                    <p>Department of labour</p>
                    <p>VAT certificate</p>
                    <p>TAX clearance certificate and TAX pin</p>
                    <p>NHBRC certificate</p>
                    <p>CSD registration</p>
                    <p>Proof of insurance including liability cover</p>
                    <p>Financials/Management accounts</p>
                    <p>Banking statements (not older than 6 months)</p>
                    <p>Letter of appointment</p>
                    <p>Implementation plan</p>
                    <p>Priced bills of quantities</p>
                    <p>Signed SPIF agreement</p>
                  </Modal>
                </Tooltip>
                {/* </Link> */}
              </IonCol>
            )}
            {current > 0 && (
              <IonCol class="ion-padding">
                <Tooltip placement="top" title={back}>
                  <Button
                    size={"large"}
                    style={{ margin: "0 8px" }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                </Tooltip>
              </IonCol>
            )}
          </div>
        </IonCol>
      </IonContent>
    </IonPage>
  );
};

export default ApplicationForm;
